import Mapbox from '@runway/mapbox';
import MapboxPoi from './sre-mapbox-poi';
import MapboxArea from './sre-mapbox-area';

const SRE_MAP_MARKER_CLASS = 'sre-map-marker';
const SRE_MAP_POPUP_DEFAULT_OFFSET = 10;

const MAPBOX_CONTROLS = {};
const MAPBOX_OPTIONS = {
    center: [4.7243032265466445, 52.30928179671997],
    maxBounds: [
        // eslint-disable-next-line no-loss-of-precision
        [4.58490838983352, 52.267105508461385],
        // eslint-disable-next-line no-loss-of-precision
        [4.853005219597369, 52.358837052950654]
    ],
    maxZoom: 20,
    minZoom: 10,
    zoom: 12,
    attributionControl: true
};

export const name = 'sre-mapbox';

export default class SreMapbox extends Mapbox {
    get name() {
        return name;
    }

    constructor(node) {
        super(node, MAPBOX_CONTROLS, MAPBOX_OPTIONS);

        this.node = node;

        this.parseJson();
    }

    // Called from Mapbox Base
    mapLoaded() {
        if (this.areasList) {
            this.setupAreas();
        }

        if (this.properties) {
            this.setupPois();
        }

        this.setupSubscriptions();
    }

    setupPois() {
        this.renderPoisOnMap();
    }

    setupAreas() {
        this.areas = [];
        this.areaNames = [];
        this.areasList.forEach(area => {
            this.areaNames.push(area.title);
            this.areas.push(new MapboxArea(this.map, area, {
                lang: this.data.lang,
                labels: {
                    readMore: this.data.buttonReadmoreLabel
                }
            }));
        });
    }

    setupSubscriptions() {
        this.subscribe('dom:.sre-map-marker:click', e => this.handlePoiClicked(e));
        this.map.on('click', e => this.handleMapClicked(e));
    }

    parseJson() {
        try {
            const scripts = this.node.querySelectorAll(`.${this.data.scriptClassname}`);

            if (!scripts) {
                throw new Error('No valid data script tag provided for SRE Mapbox component');
            }

            Array.from(scripts).forEach(script => {
                const dataString = script.innerHTML.replace(/&quot;/g, '"');

                this.cacheData(script.dataset.type, dataString);
                script.parentNode.removeChild(script);
            });
        } catch (error) {
            throw new Error(error);
        }
    }

    cacheData(type, data) {
        if (type === 'poi') {
            this.properties = JSON.parse(data);
        } else if (type === 'area') {
            this.areasList = JSON.parse(data);
        } else {
            throw new Error('mapbox data scripttag expects a data-attribute '
                + 'of "type" with value "poi" or "area"');
        }
    }

    renderPoisOnMap() {
        const { properties } = this;

        properties.forEach(property => {
            const poi = new MapboxPoi(
                property.id,
                property.coordinates,
                {
                    html: `<h3>${property.name}</h3><p>${property.address}</p>`
                },
                SRE_MAP_MARKER_CLASS
            );

            poi.marker.addTo(this.map);
        });
    }

    showAreaPopup(event) {
        // NOTE: When the clicked element is a marker, don't show the area popup by returning early.
        if (event.originalEvent.target.classList.contains(SRE_MAP_MARKER_CLASS)) {
            return;
        }

        const features = this.map.queryRenderedFeatures(event.point, {
            layers: this.areaNames
        });

        if (features && features.length) {
            const feature = features[0];

            this.acvitePopup = new window.mapboxgl.Popup({
                offset: SRE_MAP_POPUP_DEFAULT_OFFSET
            })
                .setLngLat(event.lngLat)
                .setHTML(feature.properties.popupHTML)
                .addTo(this.map);
        }
    }

    handleMapClicked(event) {
        this.showAreaPopup(event);
    }

    handlePoiClicked(event) {
        const data = {
            id: event.target.dataset.id
        };

        this.fireEvent({
            type: 'marker-click',
            data
        });
    }
}
