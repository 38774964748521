import { formatMinutesAndHours } from '../../helpers/time-format';

export const name = 'checkin-open-closed';

export default class CheckinOpenClosed {
    get name() {
        return name;
    }

    constructor() {
        this.CHECKIN_OPEN = document.querySelector('[data-checkin-open]');
        this.CHECKIN_CLOSED = document.querySelector('[data-checkin-closed]');
        this.CHECKIN_COUNTDOWN = document.querySelector('[data-checkin-countdown]');

        this.initInterval();
    }

    initInterval() {
        this.interval = setInterval(this.display.bind(this), 60000);

        return this.display(); // with return the information will be performed immediately
    }

    getCheckinOpenTime() {
        return this.data.checkinOpenTime;
    }

    getCheckinClosedTime() {
        return this.data.checkinClosedTime;
    }

    display() {
        const date = new Date(this.convertDateToAmsterdamTimeZone(new Date()));
        const currentTime = date.getTime()/1000;

        const checkinOpenTime = this.getCheckinOpenTime();
        const checkinClosedTime = this.getCheckinClosedTime();

        const checkinOpenPlus4Hours = currentTime > checkinOpenTime - 14400 && currentTime < checkinOpenTime;
        const checkinIsClosed = currentTime < checkinOpenTime || currentTime > checkinClosedTime;

        const countdownMinutes = (checkinOpenTime - currentTime) / 60;
        const countdownMinutesRounded = Math.floor(countdownMinutes); // convert ms to minutes

        if (checkinOpenPlus4Hours && countdownMinutesRounded > 0) {
            const hours = Math.floor(countdownMinutesRounded / 60);
            const minutes = countdownMinutesRounded % 60;
            const htmlLang = document.querySelector('html').getAttribute('lang') || 'nl';

            this.CHECKIN_COUNTDOWN.removeAttribute('hidden');
            document.getElementById('time-to-checkin').innerText = formatMinutesAndHours(hours, minutes, htmlLang);
        } else if (checkinIsClosed) {
            this.CHECKIN_CLOSED.removeAttribute('hidden');
        } else {
            this.CHECKIN_OPEN.removeAttribute('hidden');
        }
    }

    convertDateToAmsterdamTimeZone(date) {
        return new Date(
            date.toLocaleDateString('nl', {
                timeZone: 'Europe/Amsterdam',
                year: 'numeric'
            }) + '-' +
            date.toLocaleDateString('nl', {
                timeZone: 'Europe/Amsterdam',
                month: '2-digit'
            }) + '-' +
            date.toLocaleDateString('nl', {
                timeZone: 'Europe/Amsterdam',
                day: '2-digit'
            }) + ' ' +
            date.toLocaleTimeString('nl', {
                timeZone: 'Europe/Amsterdam',
                hour: '2-digit',
                minute: '2-digit',
                'second': '2-digit'
            })
        );
    }
}
