import { trackEvent } from '@runway/util-analytics';

export const name = 'KioskFlightSearch';

export default class KioskFlightSearch {
    get name() {
        return name;
    }

    constructor(node) {
        this.node = node;
        this.form = node.querySelector('form');
        this.direction = 'departures';
        this.endpoint = node.querySelector('[data-component="rw-autosuggest"]')
            .getAttribute('data-endpoint');

        if (!this.endpoint) {
            this.fireError({
                description: 'No endpoint found'
            });

            return;
        }

        this.subscribe('dom:#kiosk-flight-search-submit:click', () => this.handleSearchButtonClick());
        this.subscribe('shg:rw-autosuggest:choose', e => this.handleChoose(e));
        this.subscribe('shg:rw-autosuggest:enter-search', e => this.handleEnterSearch(e));
    }

    handleChoose(customEvent) {
        const { searchQuery, query, isUrl } = customEvent.detail;
        const { direction } = this;

        trackEvent('flight-info', 'choose autosuggest', searchQuery, {
            dimension57: query,
            dimension64: direction
        });

        if (!isUrl) {
            this.form.submit();
        }
    }

    handleEnterSearch(customEvent) {
        const { query } = customEvent.detail;
        const { direction } = this;

        trackEvent('flight-info', 'search enter', query, {
            dimension64: direction
        });
    }

    handleSearchButtonClick() {
        const query = this.node.querySelector('input[type="search"]').value.trim();
        const { direction } = this;

        trackEvent('flight-info', 'search button', query, {
            dimension64: direction
        });
    }
}
