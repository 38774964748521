import Analytics from "@runway/analytics";
import AttentionBar from "@runway/attention-bar";
import Autosuggest from "@runway/autosuggest";
import BoardingTime from "../components/boarding-time/boarding-time";
import CheckinOpenClosed from "../components/checkin-open-closed/checkin-open-closed";
import CheckinWaitingTime from "../components/checkin-waiting-time/checkin-waiting-time";
import CheckboxToggleVisibility from "@runway/checkbox-toggle-visibility";
import Chart from "@runway/chart";
import CookieConsent from "@runway/cookie-consent";
import DynamicArea from "@runway/dynamic-area";
import FileUpload from "@runway/file-upload";
import Header from "@runway/header";
import EnhancedForm from "@runway/enhanced-form";
import InputElement from "@runway/input-element";
import InputElementBirthDate from "@runway/input-element-birthdate";
import InputElementCheckbox from "@runway/input-element-checkbox";
import InputElementChoice from "@runway/input-element-choice";
import InputElementDate from "@runway/input-element-date";
import InputElementIban from "@runway/input-element-iban";
import InputElementNumber from "@runway/input-element-number";
import InputElementSelect from "@runway/input-element-select";
import InputElementTime from "@runway/input-element-time";
import InputTextarea from "@runway/input-textarea";
import Link from "@runway/link";
import ListLinks from "@runway/list-links";
import NewsletterForm from "@runway/newsletter-form";
import QueryForm from "@runway/query-form";
import Takeover from "@runway/takeover";
import Tabs from "@runway/tabs";
import Toggle from "@runway/toggle";
import ToggleVisibility from "@runway/toggle-visibility";
import Tooltip from "@runway/tooltip";
import VisibilitySwitch from "@runway/visibility-switch";
import Youtube from "@runway/youtube";
import PageLink from "@runway/page-link";
import StoredFlightInfo from "@schiphol/stored-flight-info";

import AddressFinder from "../components/address-finder/address-finder";
import BankAccount from "../compositions/bank-account";
import CampaignForm from "../compositions/campaign-form/campaign-form";
import ChipFilters from "../components/chip-filters/chip-filters";
import WaitingTimesChart from "../components/waiting-times/waiting-times-chart";
import { FlightList } from "../compositions/flight-list/flight-list";
import FlightSearch from "../compositions/flight-search/flight-search";
import KioskFlightSearch from "../compositions/transfer-kiosk/kiosk-flight-search";
import FlightSearchWidget from "../compositions/flight-search/flight-search-widget";
import HandleScroll from "../components/handle-scroll/handle-scroll";
import IframeSrc from "../components/iframe/iframe";
import LiveBlog from "../compositions/live-blog/live-blog";
import MyTravelDay from "../compositions/my-travel-day/my-travel-day";
import SalesforceSnapInChat from "../components/salesforce-snap-in-chat/salesforce-snap-in-chat";
import SmsLink from "../components/sms-link/sms-link";
import SreEmployeeFilter from "../compositions/sre-employee-filter/sre-employee-filter";
import SreMap from "../compositions/sre-map/sre-map";
import SreMapTab from "../compositions/sre-map/sre-map-tab";
import SreMapbox from "../compositions/sre-map/sre-mapbox";
import SreMapboxPoi from "../compositions/sre-map/sre-mapbox-poi";
import WhatsappOpenChat from "../components/whatsapp/whatsapp-open-chat";

// China search component
import ChinaFlightsCookie from "../components/flight-search/china-flights-cookie";
import ChinaFlightsRenderer from "../components/flight-search/china-flights-renderer";

Autosuggest.addRenderType("china-flights", ChinaFlightsRenderer);

const components = [
    {
        name: "rw-attention-bar",
        constructor: AttentionBar,
    },
    {
        name: "rw-autosuggest",
        constructor: Autosuggest,
    },
    {
        name: "boarding-time",
        constructor: BoardingTime,
    },
    {
        name: "checkin-open-closed",
        constructor: CheckinOpenClosed,
    },
    {
        name: "checkin-waiting-time",
        constructor: CheckinWaitingTime,
    },
    {
        name: "rw-checkbox-toggle-visibility",
        constructor: CheckboxToggleVisibility,
    },
    {
        name: "rw-chart",
        constructor: Chart,
    },
    {
        name: "chip-filters",
        constructor: ChipFilters,
    },
    {
        name: "waiting-times-chart",
        constructor: WaitingTimesChart,
    },
    {
        name: "rw-dynamic-area",
        constructor: DynamicArea,
    },
    {
        name: "rw-file-upload",
        constructor: FileUpload,
    },
    {
        name: "rw-header",
        constructor: Header,
    },
    {
        name: "rw-enhanced-form",
        constructor: EnhancedForm,
    },
    {
        name: "rw-input-element",
        constructor: InputElement,
    },
    {
        name: "rw-input-element-birthdate",
        constructor: InputElementBirthDate,
    },
    {
        name: "rw-input-element-checkbox",
        constructor: InputElementCheckbox,
    },
    {
        name: "rw-input-element-choice",
        constructor: InputElementChoice,
    },
    {
        name: "rw-input-element-date",
        constructor: InputElementDate,
    },
    {
        name: "rw-input-element-iban",
        constructor: InputElementIban,
    },
    {
        name: "rw-input-element-number",
        constructor: InputElementNumber,
    },
    {
        name: "rw-input-element-select",
        constructor: InputElementSelect,
    },
    {
        name: "rw-input-element-time",
        constructor: InputElementTime,
    },
    {
        name: "rw-input-textarea",
        constructor: InputTextarea,
    },
    {
        name: "rw-link",
        constructor: Link,
    },
    {
        name: "rw-list-links",
        constructor: ListLinks,
    },
    {
        name: "rw-newsletter-form",
        constructor: NewsletterForm,
    },
    {
        name: "rw-query-form",
        constructor: QueryForm,
    },
    {
        name: "rw-toggle",
        constructor: Toggle,
    },
    {
        name: "rw-toggle-visibility",
        constructor: ToggleVisibility,
    }, // TODO: This should be replaced by rw-toggle
    {
        name: "rw-tooltip",
        constructor: Tooltip,
    },
    {
        name: "rw-visibility-switch",
        constructor: VisibilitySwitch,
    }, // TODO: This should be replaced by rw-toggle
    {
        name: "rw-youtube",
        constructor: Youtube,
    },
    {
        name: "address-finder",
        constructor: AddressFinder,
    },
    {
        name: "analytics",
        constructor: Analytics,
    },
    {
        name: "bank-account",
        constructor: BankAccount,
    },
    {
        name: "campaign-form",
        constructor: CampaignForm,
    },
    {
        name: "rw-cookie-consent",
        constructor: CookieConsent,
    },
    {
        name: "flight-list",
        constructor: FlightList,
    },
    {
        name: "flight-search",
        constructor: FlightSearch,
    },
    {
        name: "kiosk-flight-search",
        constructor: KioskFlightSearch,
    },
    {
        name: "flight-search-widget",
        constructor: FlightSearchWidget,
    },
    {
        name: "handle-scroll",
        constructor: HandleScroll,
    },
    {
        name: "iframe-src",
        constructor: IframeSrc,
    },
    {
        name: "live-blog",
        constructor: LiveBlog,
    },
    {
        name: "my-travel-day",
        constructor: MyTravelDay,
    },
    {
        name: "whatsapp-open-chat",
        constructor: WhatsappOpenChat,
    },
    {
        name: "salesforce-snap-in-chat",
        constructor: SalesforceSnapInChat,
    },
    {
        name: "sms-link",
        constructor: SmsLink,
    },
    {
        name: "stored-flight-info",
        constructor: StoredFlightInfo,
    },
    {
        name: "sre-employee-filter",
        constructor: SreEmployeeFilter,
    },
    {
        name: "sre-map",
        constructor: SreMap,
    },
    {
        name: "sre-map-tab",
        constructor: SreMapTab,
    },
    {
        name: "sre-mapbox",
        constructor: SreMapbox,
    },
    {
        name: "sre-mapbox-poi",
        constructor: SreMapboxPoi,
    },
    {
        name: "rw-takeover",
        constructor: Takeover,
    },
    {
        name: "rw-tabs",
        constructor: Tabs,
    },
    {
        name: "china-flights-cookie",
        constructor: ChinaFlightsCookie,
    },
    {
        name: "rw-page-link",
        constructor: PageLink,
    },
];

export default components;
