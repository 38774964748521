import debounce from '@runway/util-debounce';

// Scroll actions
import stickyHeader from './handlers/sticky-header';

export const name = 'handle-scroll';
const DEBOUCE_DELAY = 15;

/**
 * This config object defines a name
 * and function (with optional extra init data)
 * to be executed within a debounce on scroll
 */
const ScrollHandlerConfig = {
    'sticky-header': {
        handler: stickyHeader,
        getHandlerParams: () => [document.querySelector('[data-scroll-handler="sticky-header"]').offsetTop]
    }
};

export default class HandleScroll {
    get name() {
        return name;
    }

    /**
     * Find the appropriate handler
     * from within the config object
     * ScrollHandlerConfig and execute
     * within debounce on scroll
     */
    constructor(node) {
        this.node = node;

        const { scrollHandler } = this.data;
        const { handler, getHandlerParams } = ScrollHandlerConfig[scrollHandler];

        const handlerParams = getHandlerParams();

        document.addEventListener('scroll', debounce(() => handler(...handlerParams), DEBOUCE_DELAY, true));
    }
}
