import { trackEvent } from "@runway/util-analytics";

const setupChipFilters = (boardingTimeString, flightId) => {
    const boardingTime = new Date(boardingTimeString);
    const now = new Date();
    const isFlightDataRelevantForLocalState = boardingTime > now;
    const itineraryStateKey = `schiphol-itinerary-filters-state-${flightId}`;
    const itineraryInitialStateObject = {
        "already-checked-in": false,
        "hand-luggage-only": false,
    };
    const itineraryStoredStateString = localStorage.getItem(itineraryStateKey);
    let itineraryState = JSON.parse(
        itineraryStoredStateString ? itineraryStoredStateString : "{}"
    );

    if (!isFlightDataRelevantForLocalState) {
        localStorage.removeItem(itineraryStateKey);
    }

    if (!Object.keys(itineraryState).length) {
        localStorage.setItem(
            itineraryStateKey,
            JSON.stringify(itineraryInitialStateObject)
        );
    }

    const chips = document.querySelectorAll('[data-chip="filter"]');
    const itineraryList = document.getElementById("itinerary");

    const tipIds = {
        adviseToggle: "advise-toggle",
        whenToBeAtSchiphol: "when-to-be-at-schiphol",
        boardingTime: "boarding-time",
        checkInAndBaggageDropoffBlock: "check-in-and-baggage-dropoff",
        checkInAndBaggageDropoff: {
            header1: "check-in-and-baggage-dropoff-header-1",
            header2: "check-in-and-baggage-dropoff-header-2",
            header3: "check-in-and-baggage-dropoff-header-3",
            body3: "check-in-and-baggage-dropoff-body-3",
            time: "check-in-and-baggage-dropoff-time"
        },
        checkedinReroute: "checkedin-reroute",
        checkinHome: "checkin-home",
        departureInformation: "departure-information",
        findYourArrivingFlight: "find-your-arriving-flight",
        findYourDepartingFlight: "find-your-departing-flight",
        passportCheck: "passport-check",
        preparation: {
            tip1: "preparation-tip-1",
            tip2: "preparation-tip-2",
            tip3: "preparation-tip-3",
            tip4: "preparation-tip-4",
        },
        securityWaitingTimesTooltip: "security-waiting-times-tooltip",
        securityWaitingTimes: "security-waiting-times",
        timeToRelax: "time-to-relax",
        toSchiphol: "to-schiphol",
        transfersInformation: "transfers-information",
        schipholAdviseBlock: "schiphol-advise",
        schipholAdvise: {
            headerFixedTime: "schiphol-advise-header-fixed-time",
            headerCheckInTime: "schiphol-advise-header-check-in-time",
        },
    };

    const getTip = name => {
        return document.querySelector(`[data-tip="${name}"]`);
    }

    const hideCrowdLevelsBlockWhenTimeToRelaxStepFinishesSoBoardingStarts = () => {
        const status = document.querySelector('[data-tip="time-to-relax"]')?.getAttribute('data-step-has-passed');

        if (status === 'true') {
            document.querySelector('.schiphol-advise')?.classList.add('hidden');
        }
    }

    const hideAdviseBlockWhenCheckInBaggageStepStarts = () => {
        const whenToBeAtSchipholStatus = document.querySelector('[data-tip="when-to-be-at-schiphol"]')?.getAttribute('data-step-has-passed');

        if (whenToBeAtSchipholStatus === 'true') {
            getTip(tipIds.schipholAdvise.headerFixedTime)?.classList.add('hidden');
            getTip(tipIds.schipholAdvise.headerCheckInTime)?.classList.add('hidden');
            getTip(tipIds.adviseToggle)?.classList.add('hidden');
        }
    }

    const hideFiltersWhenCheckInBaggageStepFinished = () => {
        const status = document.querySelector('[data-tip="check-in-and-baggage-dropoff"]').getAttribute('data-step-has-passed');

        if (status === 'true') {
            document.querySelector('.flight-details-itinerary__tagline').classList.add('hidden');
            document.querySelector('.itinerary-filters').classList.add('hidden');
        }
    }

    const foldCheckinAndBaggagePanel = () => {
        const panel = document.querySelector('[data-tip="check-in-and-baggage-dropoff"] .itinerary__body');

        panel.setAttribute('hidden', 'true');
    }

    const hideWalkingTimesBlockWhenBoardingTimeStepFinished = () => {
        const status = document.querySelector('[data-tip="boarding-time"]').getAttribute('data-step-has-passed');

        if (status === 'true') {
            getTip('walking-time-to-gate')?.classList.add('hidden');
        }
    }

    const isCheckInTimeAvailable = () => {
        return document.querySelector('[data-tip="schiphol-advise-header-check-in-time"]')?.getAttribute('data-is-check-in-time-available') === 'true';
    }

    const showSchipholAdviseForCheckInTimeOrFixed = isCheckInTimeAvailable => {
        if (! itineraryState["already-checked-in"] || ! itineraryState["hand-luggage-only"]) {
            if (isCheckInTimeAvailable) {
                showCheckInTimeSchipholAdvise();

                return;
            }
        }

        showFixedSchipholAdvise();
    }

    const showFixedSchipholAdvise = () => {
        getTip(tipIds.schipholAdvise.headerFixedTime)?.classList.remove('hidden');
        getTip(tipIds.schipholAdvise.headerCheckInTime)?.classList.add('hidden');
    }

    const showCheckInTimeSchipholAdvise = () => {
        getTip(tipIds.schipholAdvise.headerFixedTime)?.classList.add('hidden');
        getTip(tipIds.schipholAdvise.headerCheckInTime)?.classList.remove('hidden');
    }

    const filter = () => {
        const tips = document.querySelectorAll("[data-tip]");

        for (let tip of tips) {
            const tipId = tip.dataset.tip;

            tip.classList.remove("hidden");
            tip.classList.remove("inactive");

            if (
                itineraryState["already-checked-in"] &&
                itineraryState["hand-luggage-only"]
            ) {
                switch (tipId) {
                    case tipIds.checkInAndBaggageDropoff.header1:
                    case tipIds.checkInAndBaggageDropoffBlock:
                        tip.classList.add("inactive");
                        break;
                    case tipIds.checkInAndBaggageDropoff.time:
                    case tipIds.preparation.tip1:
                    case tipIds.preparation.tip3:
                        tip.classList.add("hidden");
                        break;
                }
            }

            if (
                itineraryState["already-checked-in"] &&
                !itineraryState["hand-luggage-only"]
            ) {
                switch (tipId) {
                    case tipIds.checkinHome:
                    case tipIds.preparation.tip1:
                    case tipIds.checkInAndBaggageDropoff.header1:
                    case tipIds.checkInAndBaggageDropoff.header2:
                        tip.classList.add("hidden");
                        break;
                }
            } else if (
                itineraryState["hand-luggage-only"] &&
                !itineraryState["already-checked-in"]
            ) {
                switch (tipId) {
                    case tipIds.preparation.tip3:
                    case tipIds.checkInAndBaggageDropoff.header1:
                    case tipIds.checkInAndBaggageDropoff.header3:
                    case tipIds.checkInAndBaggageDropoff.body3:
                        tip.classList.add("hidden");
                        break;
                }
            } else if (
                !itineraryState["hand-luggage-only"] &&
                !itineraryState["already-checked-in"]
            ) {
                switch (tipId) {
                    case tipIds.checkInAndBaggageDropoff.header2:
                    case tipIds.checkInAndBaggageDropoff.header3:
                        tip.classList.add("hidden");
                        break;
                }
            } else {
                switch (tipId) {
                    case tipIds.checkInAndBaggageDropoff.header2:
                    case tipIds.checkInAndBaggageDropoff.header3:
                        tip.classList.add("hidden");
                        break;
                }
            }
        }

        if (itineraryState["already-checked-in"] && itineraryState["hand-luggage-only"]) {
            foldCheckinAndBaggagePanel();
        }

        hideAdviseBlockWhenCheckInBaggageStepStarts();
        hideCrowdLevelsBlockWhenTimeToRelaxStepFinishesSoBoardingStarts();
        hideFiltersWhenCheckInBaggageStepFinished();
        hideWalkingTimesBlockWhenBoardingTimeStepFinished();
        showSchipholAdviseForCheckInTimeOrFixed(isCheckInTimeAvailable());
    };

    for (let chip of chips) {
        chip.checked = itineraryState[chip.id];
        filter(chip.id, chip.checked); // Filter on load

        chip.addEventListener("click", event => {
            event.stopPropagation();

            if (event.target.type === "checkbox") {
                const input = event.target;
                const preparationHeader = document.querySelector('[data-tip="preparation"] [data-trigger]');
                const preparationBody = document.querySelector('[data-tip="preparation"] [data-panel]');

                input.parentElement.parentElement.classList.add("disabled"); // Disable chip (group) to avoid animation clashes

                itineraryList.classList.add("hide"); // Hide the list

                preparationHeader.setAttribute("aria-expanded", false);
                preparationBody.setAttribute("hidden", true);

                setTimeout(() => {
                    itineraryList.classList.remove("hide"); // Show the list
                    input.parentElement.parentElement.classList.remove(
                        "disabled"
                    ); // Enable chip (group)
                }, 300);

                itineraryState[chip.id] = chip.checked;
                localStorage.setItem(
                    itineraryStateKey,
                    JSON.stringify(itineraryState)
                );

                trackEvent(
                    "fdp itinerary",
                    "filter",
                    `${input.id}-checked:${chip.checked}`
                );

                return filter(input.id, input.checked); // Filter on click
            }
        });
    }
};

window.setupChipFilters = setupChipFilters;

export default setupChipFilters;
