export function formatMinutesAndHours(hours, minutes, language) {
    if (! ['en', 'nl'].includes(language)) {
        language = 'en';
    }

    let andLabel;
    let hourLabel;
    let hoursLabel;
    let minuteLabel;
    let minutesLabel;
    let formattedHours, formattedMinutes;

    if (language === 'en') {
        andLabel = 'and';
        hourLabel = 'hour';
        hoursLabel = 'hours';
        minuteLabel = 'minute';
        minutesLabel = 'minutes';
    } else if (language === 'nl') {
        andLabel = 'en';
        hourLabel = 'uur';
        hoursLabel = 'uur';
        minuteLabel = 'minuut';
        minutesLabel = 'minuten';
    }

    if (hours === 1) {
        formattedHours = `${hours} ${hourLabel}`;
    } else if (hours > 1) {
        formattedHours = `${hours} ${hoursLabel}`;
    }

    if (minutes === 1) {
        formattedMinutes = `${minutes} ${minuteLabel}`;
    } else if (minutes > 1) {
        formattedMinutes = `${minutes} ${minutesLabel}`;
    }

    return [formattedHours, formattedMinutes].filter(Boolean).join(` ${andLabel} `);
}
