import SHG from "@runway/conductor";
import * as AnalyticsUtil from "@runway/util-analytics";
import Analytics from "@schiphol/analytics";
import { getEnvironment } from "@schiphol/env";
import { reportCustomError, setupSentry } from "@schiphol/sentry";
import supportsLocalStorage from "@schiphol/supports-local-storage";
import {
    manualTriggerUsabillaCampagins, restrictMultipleUsabillaCampaigns, setGoogleIdInUsabilla,
} from "@schiphol/util-usabillia";
import "lazysizes";
import componentList from "./component-list";
import { ecommerce } from "./utilities/analytics-configuration";
import { install as installResizeObserver } from 'resize-observer';

/* eslint-disable max-statements */
(function index() {
    const htmlNode = document.documentElement;

    // And that we have JS
    htmlNode.classList.remove("no-js");
    htmlNode.classList.remove("loading-js");
    htmlNode.classList.add("javascript");

    window.SHG = SHG;
    window.SHG.register(componentList);

    // Polyfill ResizeObserver for Safari
    if (!window.ResizeObserver) {
        installResizeObserver();
    }

    document.addEventListener("DOMContentLoaded", boot);
    window.addEventListener("load", boot);

    function boot() {
        document.removeEventListener("DOMContentLoaded", boot);
        window.removeEventListener("load", boot);

        AnalyticsUtil.init(ecommerce);

        const analytics = new Analytics();
        const gtmId = htmlNode.getAttribute("data-gtm-id");

        analytics.init(gtmId);

        // this is the page view
        Analytics.track({
            "gtm.start": new Date().getTime(),
            event: "gtm.js",
        });

        initSHG();
    }

    function initSHG() {
        window.SHG.init();
        window.SHG.processTrackingEvents(processTrackEvents);
        window.SHG.processShgErrors(reportCustomError);
        window.SHG.scan();

        setupErrorTracking();
        initSentry();
        setupUsabilla();

        setupPageLinks();
        measureKioskVisits();
        weChatOverlay();
    }

    function setupUsabilla() {
        // Do not allow a user to receive more
        // than one usabilla campaign per month
        restrictMultipleUsabillaCampaigns();
        setGoogleIdInUsabilla();
        manualTriggerUsabillaCampagins();
    }

    /**
     * Turn all in-page links into rw-page-link components
     */
    function setupPageLinks() {
        const anchorNodes = document.body.querySelectorAll('a[href^="#"]');

        Array.from(anchorNodes).forEach(anchorNode => {
            if (
                anchorNode.hasAttribute("data-component") ||
                anchorNode.hasAttribute("data-no-scroll")
            ) {
                return;
            }

            anchorNode.setAttribute("data-component", "rw-page-link");
        });
    }

    /**
     * Listens for SHG_FORM_ERROR
     * and tracks to Google Analytics
     */
    function setupErrorTracking() {
        const eventType = "SHG_FORM_ERROR";

        window.addEventListener(eventType, errorEvent => {
            // TODO Add categories to base.html.twig so we can
            // more easily and cleanly report these kinds of things per category
            const { name = "", errorMessage = "" } = errorEvent.detail;

            // Some input names have product ids,
            // strip product ids from name
            const label = name.replace(
                /[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}/g,
                ""
            );

            // We would like to have formName in the action field for Analytics
            // Unfortunately not all of them have name attribute set
            // The fallback mechanism is form.name || labelNameSpace || the location of the form error
            const formName =
                errorEvent.detail.formName || label.indexOf("[") > -1
                    ? label.split("[")[0]
                    : window.location.pathname;

            AnalyticsUtil.trackEvent("form error", formName, label, {
                errorText: errorMessage,
            });
        });
    }

    function initSentry() {
        const environment =
            getEnvironment() === "prod" ? "production" : "acceptance";
        const dsn = htmlNode.getAttribute("data-sentry-dsn");
        const release = htmlNode.getAttribute("data-ver") || "unknown";
        const assetUrls =
            environment === "production"
                ? ["cdn.schiphol.nl/dist/javascript"]
                : ["cdn.digitalredesign.nl/dist/javascript"];
        // ignore errors that are generated by outlook link preview
        const ignoreErrors = [
            "/Non-Error promise rejection captured with value: Object Not Found Matching Id:[0-9]+/",
        ];

        const config = {
            dsn,
            release,
            environment,
            ignoreErrors,
            whitelistUrls: assetUrls,
        };

        setupSentry(config);
    }

    function processTrackEvents(event) {
        const { eventCategory, eventAction, eventLabel, eventOptions } =
            event.detail;

        AnalyticsUtil.trackEvent(
            eventCategory,
            eventAction,
            eventLabel,
            eventOptions
        );
    }

    /**
     * This is for iPad Kiosks that are in the Terminal!
     * Parse the user agent string to check if it contains a Kiosk ID that
     * should be pushed to the GTM dataLayer.
     * If in the future we find a different way to measure Kiosk App usage
     * this can be removed
     */
    function measureKioskVisits() {
        const kioskRegExp = /SHGKiosk:\s*(.+)$/i;
        const isKiosk = kioskRegExp.test(navigator.userAgent);

        if (isKiosk && shouldMeasureKioskVisits()) {
            const kioskID = kioskRegExp.exec(navigator.userAgent)[1];

            const thirtyDays = 30 * 24 * 60 * 60 * 1000; // days * hours a day * minutes * seconds * miliseconds
            const kioskExpiryDate = new Date().getTime() + thirtyDays;

            if (supportsLocalStorage()) {
                window.localStorage.setItem("kioskExpiryDate", kioskExpiryDate);
            }

            AnalyticsUtil.trackData({
                event: "UserAgent",
                eventAction: kioskID,
                eventAgentCode: "WEB",
                eventCategory: "KioskID",
                eventEnvironment: "new website",
                eventLabel: undefined,
            });
        }
    }

    /**
     * Check if the stored Kiosk Expiry Date is in the past
     *
     * Return false if there isn't a kioskExpiryDate stored or if the stored date has expired
     */
    function shouldMeasureKioskVisits() {
        const kioskExpiryDate = window.localStorage.getItem("kioskExpiryDate");

        return kioskExpiryDate ? kioskExpiryDate < new Date().getTime() : true;
    }

    function weChatOverlay() {
        const wechatOverlayNode = document.getElementById("js-wechat-overlay");

        if (!wechatOverlayNode) {
            return;
        }

        const wechatButtonNode = document.querySelector("#js-wechat-open");
        const closeOverlayNode = wechatOverlayNode.querySelector(
            "#js-wechat__close-container"
        );

        if (wechatButtonNode) {
            wechatButtonNode.addEventListener("click", () => {
                wechatOverlayNode.style.display = "block";
            });
        }

        if (closeOverlayNode) {
            const closeButton = closeOverlayNode.querySelector("button");

            closeButton.addEventListener("click", () => {
                wechatOverlayNode.style.display = "none";
            });
        }
    }
})();
/* eslint-enable max-statements */
