import * as Dom from '@runway/util-dom';
import waitForDeferred from '@runway/util-defer';

const LIVEAGENT_ONLINE_ID = 'liveagent-online';
const LIVEAGENT_OFFLINE_ID = 'liveagent-offline';
const LOAD_TIMEOUT = 10 * 1000; // 10 seconds

const liveagentIsLoaded = () => window.liveagent;

const name = 'salesforce-snap-in-chat';

export default class SalesforceSnapInChat {
    get name() {
        return name;
    }

    constructor(node) {
        Dom.show(node);

        this.node = node;
        this.storeDataAttributeValues();
        this.loadSalesforceChat();
    }

    storeDataAttributeValues() {
        this.agentName = this.data.agentName;
        this.chatUrl = this.data.chatUrl;
        this.contentUrl = this.data.contentUrl;
        this.deploymentId = this.data.deploymentId;
        this.deploymentUrl = this.data.deploymentUrl;
        this.embeddedServiceUrl = this.data.embeddedServiceUrl;
        this.key = this.data.key;
        this.language = this.data.language;
        this.buttonId = this.data.buttonId;
        this.offlineButtonNode = this.node.querySelector(`#${LIVEAGENT_OFFLINE_ID}`);
        this.onlineButtonNode = this.node.querySelector(`#${LIVEAGENT_ONLINE_ID}`);
        this.organisationId = this.data.organisationId;
        this.snapInUrl = this.data.snapInUrl;
        this.storageDomain = this.data.storageDomain;
        this.url = this.data.url;
    }

    loadSalesforceChat() {
        let loadDeploymentAndEmbedServiceScripts;

        try {
            loadDeploymentAndEmbedServiceScripts = Dom.loadScripts(
                [
                    this.deploymentUrl,
                    this.embeddedServiceUrl
                ],
                LOAD_TIMEOUT
            );
        } catch (e) {
            throw new Error(
                `Error loading: ${this.deploymentUrl}. Chat not initialized. Error description: ${e.message}`
            );
        }

        Promise.all(loadDeploymentAndEmbedServiceScripts).then(() => {
            waitForDeferred(name, liveagentIsLoaded)
                .then(() => {
                    this.initSalesforceChat();
                })
                .catch(error => {
                    this.fireError({
                        description: error.message,
                        error
                    });
                });

            const {
                agentName,
                buttonId,
                contentUrl,
                chatUrl,
                deploymentId,
                language,
                key,
                organisationId,
                snapInUrl,
                storageDomain,
                url
            } = this;

            const initESW = gslbBaseURL => {
                window.embedded_svc.settings.displayHelpButton = false;
                window.embedded_svc.settings.language = language;
                window.embedded_svc.settings.storageDomain = storageDomain;
                window.embedded_svc.settings.enabledFeatures = ['LiveAgent'];
                window.embedded_svc.settings.entryFeature = 'LiveAgent';

                // Attach a case to the transcript.
                window.embedded_svc.settings.extraPrechatFormDetails = [
                    {
                        label: 'Status',
                        value: 'New',
                        displayToAgent: false
                    },
                    {
                        label: 'First Name',
                        name: 'FirstName',
                        value: language === 'en' ? 'Me' : 'Ik',
                        displayToAgent: false
                    },
                    {
                        label: 'Origin',
                        value: 'Chat',
                        displayToAgent: false
                    }
                ];

                if (this.shouldAddExtraFormDetailForCoronaPages()) {
                    // This is extra information added to the chat on those pages that are not schiphol.nl/en/contact-schiphol
                    // This is part of a pilot performed during Coronavirus crisis.
                    const newFormDetails = {
                        label: 'Type of Chat',
                        value: 'Corona',
                        transcriptFields: [ 'Type_of_Chat__c' ],
                        displayToAgent: false
                    }

                    window.embedded_svc.settings.extraPrechatFormDetails.splice(2, 0, newFormDetails);
                }

                window.embedded_svc.settings.extraPrechatInfo = [
                    {
                        entityName: 'Case',
                        showOnCreate: true,
                        saveToTranscript: 'CaseId',
                        entityFieldMaps: [
                            {
                                doCreate: true,
                                doFind: false,
                                isExactMatch: true,
                                fieldName: 'Status',
                                label: 'Status'
                            },
                            {
                                doCreate: true,
                                doFind: false,
                                isExactMatch: true,
                                fieldName: 'Origin',
                                label: 'Origin'
                            }
                        ]
                    }
                ];

                window.embedded_svc.init(
                    url,
                    snapInUrl,
                    gslbBaseURL,
                    organisationId,
                    key,
                    {
                        baseLiveAgentContentURL: contentUrl,
                        deploymentId,
                        buttonId,
                        baseLiveAgentURL: chatUrl,
                        eswLiveAgentDevName: agentName,
                        isOfflineSupportEnabled: false
                    }
                );
            };

            if (!window.embedded_svc) {
                Dom
                    .loadScript('https://schipholccc.my.salesforce.com/embeddedservice/5.0/esw.min.js', LOAD_TIMEOUT)
                    .then(() => {
                        initESW(null);
                    });
            } else {
                initESW('https://service.force.com');
            }

            this.subscribe('dom:.rw-top-tasks__target:click', () => this.handleClick());
        }).catch(error => {
            this.fireError({
                description: error.message,
                error
            });
        });
    }

    initSalesforceChat() {
        const { liveagent } = window;

        const {
            chatUrl,
            deploymentId,
            buttonId,
            onlineButtonNode,
            offlineButtonNode,
            organisationId
        } = this;

        window._laq = window._laq || [];
        window._laq.push(() => {
            liveagent.showWhenOnline(buttonId, onlineButtonNode);
            liveagent.showWhenOffline(buttonId, offlineButtonNode);
        });

        liveagent.init(chatUrl, deploymentId, organisationId);
    }

    handleClick() {
        // Salesforce script is not always reliable. This ugly checking is needed to not throw unnecesary errors
        if (!window.embedded_svc || !window.embedded_svc.inviteAPI || !window.embedded_svc.inviteAPI.inviteButton) {
            return;
        }

        window.embedded_svc.inviteAPI.inviteButton.acceptInvite();
    }

    shouldAddExtraFormDetailForCoronaPages() {
        return !window.location.pathname.match(/(en|nl)\/contact-schiphol/g);
    }
}
