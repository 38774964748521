export const name = 'sre-mapbox-area';

export default class SreMapboxPoi {
    get name() {
        return name;
    }

    constructor(map, area, popupData) {
        this.map = map;
        this.popupData = popupData;

        this.drawArea(area);
    }

    mapCoordinates({ coordinates }) {
        let coords = [];

        try {
            coords = coordinates.map(el => {
                el.push(el.splice(0, 1)[0]);

                return el;
            });
        } catch (error) {
            throw new Error(`No valid coordinate format found for ${coordinates}.`);
        }

        return coords;
    }

    createPopupHtmlForArea(area) {
        const { lang, labels } = this.popupData;

        let html = `<p class="rw-map-area-popup-body">${area.title}</p>`;

        if (area.link) {
            html += `<a href="${area.link[lang]}" target="_blank" class="rw-icon-link">
                <span>${labels.readMore}</span>
                <svg aria-hidden="true" class="rw-icon rw-icon--arrow-right" width="24" height="24" viewBox="0 0 24 24">
                    <path fill="currentcolor" d="M12 6l-1.4 1.4 3.5 3.6H6v2h8.2l-3.6 3.6L12 18l6-6-6-6z"></path>
                </svg>
            </a>`;
        }

        return html;
    }

    drawArea(area) {
        this.map.addLayer({
            id: area.title,
            type: 'fill',
            source: {
                type: 'geojson',
                data: {
                    type: 'Feature',
                    geometry: {
                        type: 'Polygon',
                        coordinates: [
                            this.mapCoordinates(area)
                        ]
                    },
                    properties: {
                        popupHTML: this.createPopupHtmlForArea(area)
                    }
                }
            },
            layout: {},
            paint: {
                'fill-color': area.color,
                'fill-opacity': area.opacity || 1
            }
        });
    }
}
