export const name = 'boarding-time';

export default class BoardingTime {
    get name() {
        return name;
    }

    constructor() {
        this.BOARDING_NOW_ELEMENT_ID = 'flight-status-boarding-now';
        this.EXPECTED_BOARDING_ELEMENT_ID = 'flight-status-description';
        this.boardingTime = Date.parse(this.getBoardingTimeFromDataAttribute());
        this.initInterval();
    }

    initInterval() {
        this.interval = setInterval(this.display, 60000);
        
        return this.display(); // with return the information will be performed immediately
    }

    getBoardingTimeFromDataAttribute() {
        return this.data.boardingTime;
    }

    displayExpectedBoarding(timeToBoarding) {
        document.getElementById('time-to-boarding').innerText = String(timeToBoarding) + " min.";
        document.getElementById(this.EXPECTED_BOARDING_ELEMENT_ID).style.display = 'block';
        document.getElementById(this.BOARDING_NOW_ELEMENT_ID).style.display = 'none';
    }

    displayBoardingNow() {
        document.getElementById(this.EXPECTED_BOARDING_ELEMENT_ID).style.display = 'none';
        document.getElementById(this.BOARDING_NOW_ELEMENT_ID).style.display = 'block';
    }

    hideBoarding() {
        document.getElementById(this.EXPECTED_BOARDING_ELEMENT_ID).style.display = 'none';
        document.getElementById(this.BOARDING_NOW_ELEMENT_ID).style.display = 'none';
    }

    display() {
        const timeToBoarding = Math.floor((this.boardingTime - new Date(this.convertDateToAmsterdamTimeZone(new Date()))) / 60000); // convert ms to minutes

        if (timeToBoarding > 4 * 60) {
            this.hideBoarding();
        } else if (timeToBoarding > 0) {
            this.displayExpectedBoarding(timeToBoarding);
        } else {
            this.displayBoardingNow();
            clearInterval(this.interval);
        }
    }

    convertDateToAmsterdamTimeZone(date) {
        return new Date(
            date.toLocaleDateString('nl', {
                timeZone: 'Europe/Amsterdam',
                year: 'numeric' 
            }) + '-' +
            date.toLocaleDateString('nl', {
                timeZone: 'Europe/Amsterdam',
                month: '2-digit' 
            }) + '-' +
            date.toLocaleDateString('nl', {
                timeZone: 'Europe/Amsterdam',
                day: '2-digit' 
            }) + ' ' +
            date.toLocaleTimeString('nl', {
                timeZone: 'Europe/Amsterdam',
                hour: '2-digit',
                minute: '2-digit',
                'second': '2-digit' 
            })
        );
    }
}
