/**
 * Add a node at the beginning of another node
 * @param {Object} node - the node to append
 * @param {Object} parentNode - the node which to append to
 */
export function appendNode(node, parentNode) {
    if ((node instanceof HTMLElement || node instanceof DocumentFragment)
        && (parentNode instanceof HTMLElement || parentNode instanceof DocumentFragment)) {
        parentNode.appendChild(node);
    }
}
