export const name = 'sre-map';

export default class SreMap {
    get name() {
        return name;
    }

    constructor(node) {
        this.node = node;
    }
}
