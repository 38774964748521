const name = 'my-travel-day';

export default class MyTravelDay {
    get name() {
        return name;
    }

    constructor(node) {
        this.node = node;

        this.subscribe('dom:change', e => this.handleChange(e));
    }

    handleChange(changeEvent) {
        const { target } = changeEvent;

        target.form.submit();
    }
}
