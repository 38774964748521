import { trackEvent } from "@runway/util-analytics";
export const name = "checkin-waiting-time";

export default class CheckinWaitingTime {
    get name() {
        return name;
    }

    constructor() {
        this.CHECKIN_WAITINGTIME_ELEMENT_ID = "checkin-waiting-time";
        this.initInterval();
        this.eventFired = false;
    }

    initInterval() {
        this.interval = setInterval(() => this.display(0, 0), 60000);
        return this.display(0, 0);
    }

    getCheckinOpenTime() {
        return this.data.checkinOpenTime;
    }

    getCheckinClosedTime() {
        return this.data.checkinClosedTime;
    }

    display(minWaitTimeInSeconds, maxWaitTimeInSeconds) {
        const date = new Date(this.convertDateToAmsterdamTimeZone(new Date()));
        const currentTime = date.getTime() / 1000;
        const checkinOpenTime = this.getCheckinOpenTime();
        const checkinClosedTime = this.getCheckinClosedTime();
        const checkinIsClosed =
            currentTime < checkinOpenTime || currentTime > checkinClosedTime;

        const checkInCounterElement = document.querySelector(
            `[data-component="${this.CHECKIN_WAITINGTIME_ELEMENT_ID}"]`
        );
        const baseUrl =
            checkInCounterElement.getAttribute("data-api-url") || "";

        if (checkInCounterElement && !checkinIsClosed) {
            const checkInCounter =
                checkInCounterElement.getAttribute("data-checkin-desk");
            const [start, end] = checkInCounter.split("-").map(Number);

            const numbers = Array.from(
                {
                    length: end - start + 1,
                },
                (_, index) => start + index
            );

            const apiEndpoint = `${baseUrl}desk-rows/${numbers.join(",")}`;

            fetch(apiEndpoint)
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    }

                    return response.json().then(function (json) {
                        throw json;
                    });
                })
                .then((data) => {
                    minWaitTimeInSeconds =
                        minWaitTimeInSeconds || data.minWaitTimeInSeconds;
                    maxWaitTimeInSeconds =
                        maxWaitTimeInSeconds || data.maxWaitTimeInSeconds;

                    let minWaitMinutes = Math.floor(minWaitTimeInSeconds / 60);
                    let maxWaitMinutes = Math.floor(maxWaitTimeInSeconds / 60);

                    if (maxWaitMinutes === 0) {
                        maxWaitMinutes = 1;
                        maxWaitTimeInSeconds = 60;
                    }

                    minWaitMinutes = Math.max(minWaitMinutes, 1);

                    let displayedWaitTime;

                    if (minWaitMinutes === maxWaitMinutes) {
                        displayedWaitTime =
                            minWaitMinutes +
                            (minWaitMinutes === 1 ? " min" : " mins");
                    } else {
                        displayedWaitTime =
                            minWaitMinutes +
                            " - " +
                            maxWaitMinutes +
                            (maxWaitMinutes === 1 ? " min" : " mins");
                    }

                    const checkInWaitingTimeElement =
                        checkInCounterElement.querySelector(
                            "#checkin-waiting-time"
                        );

                    if (checkInWaitingTimeElement) {
                        checkInWaitingTimeElement.textContent =
                            displayedWaitTime;

                        if (!this.eventFired) {
                            trackEvent(
                                "flight-info",
                                "checkin waitingtimes impression",
                                displayedWaitTime
                            );

                            this.eventFired = true;
                        }
                    }

                    checkInCounterElement.removeAttribute("hidden");
                })
                .catch((error) => {
                    console.error(
                        "An error occurred while retrieving the API data:",
                        error
                    );
                });
        }
    }
    convertDateToAmsterdamTimeZone(date) {
        return new Date(
            date.toLocaleDateString("nl", {
                timeZone: "Europe/Amsterdam",
                year: "numeric",
            }) +
                "-" +
                date.toLocaleDateString("nl", {
                    timeZone: "Europe/Amsterdam",
                    month: "2-digit",
                }) +
                "-" +
                date.toLocaleDateString("nl", {
                    timeZone: "Europe/Amsterdam",
                    day: "2-digit",
                }) +
                " " +
                date.toLocaleTimeString("nl", {
                    timeZone: "Europe/Amsterdam",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                })
        );
    }
}
