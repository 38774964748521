import { sample } from './helpers/sample';

export const name = 'whatsapp-open-chat';

export default class WhatsappOpenChat {
    constructor(node) {
        const numbers = this.data.numbers.split(/\s*,\s*/);

        node.href = `https://wa.me/${sample(numbers)}`;
    }

    get name() {
        return name;
    }
}
