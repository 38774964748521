import { RESULT_ITEM_CLASS, STATIC_ITEM_CLASS } from '@runway/autosuggest';
import * as Dom from '@runway/util-dom';

const RESULT_FLIGHTS_CLASS = 'rw-autosuggest__results-flights';

export default class ChinaFlightsRenderer {
    constructor(response, node, searchQuery) {
        this.node = node;
        this.searchQuery = searchQuery;

        const results = response;
        const keep = ['flights', 'messages'];

        Object.keys(results).forEach(key => {
            const hasEntries = Object.prototype.hasOwnProperty.call(results[key], 'entries');

            if (!hasEntries || keep.indexOf(key) === -1) {
                delete results[key];
            }
        });

        this.results = results;
    }

    formatResults() {
        const { results } = this;

        const fragment = document.createDocumentFragment();

        Object.keys(results)
            .map(groupName => this.formatGroup(results[groupName], groupName))
            .forEach(formattedGroup => fragment.appendChild(formattedGroup));

        return fragment;
    }

    formatGroup(group, groupName) {
        const { title, entries } = group;
        const groupNode = Dom.create({
            tag: 'li',
            className: RESULT_FLIGHTS_CLASS,
            attributes: [{
                name: 'data-group-name',
                value: groupName 
            }]
        });

        groupNode.innerHTML = `
            ${title ? `<p class="rw-autosuggest__title">${title}</p>` : ''}
            ${this.formatEntries(entries)}
        `;

        return groupNode;
    }

    formatEntries(entries) {
        return `
            <ul class="rw-autosuggest-group china-flight-search">
                ${entries.map(entry => this.formatEntry(entry)).join('')}
            </ul>
        `;
    }

    formatEntry(entry) {
        const { type, data } = entry;

        switch (type) {
            case 'flight':
                return this.formatFlight(data);
            case 'message':
                return this.formatMessage(data);
            default:
                // fail silently in case we get something we don't know yet
                return '';
        }
    }

    formatFlight(data) {
        const { flightIdentifier, flightNumber, airport, expectedTime, originalTime = '' } = data;

        // the expected time is hidden for assistive technology because the original time is
        // what people would be searching for.
        const timeMarkup = `
            <ins class="rw-autosuggest-group__time--expected" aria-hidden="true">
                <time>${expectedTime}</time>
            </ins>
            <del class="rw-autosuggest-group__time--deleted">
                <time>${originalTime}</time>
            </del>
        `;

        return `
            <li class="${RESULT_ITEM_CLASS}"
                data-id="${flightIdentifier}"
                data-value="${flightIdentifier}"
                data-airport="${airport}"
                data-flight-number="${flightNumber}"
                data-original-time="${originalTime}">

                <span class="rw-autosuggest-group__flightnumber">${flightNumber}</span>
                <span class="rw-autosuggest-group__airport">${airport}</span>
                <div class="rw-autosuggest-group__time">
                    ${originalTime === expectedTime ? `<time>${expectedTime}</time>` : timeMarkup}
                </div>
            </li>
        `;
    }

    formatMessage(data) {
        const { text } = data;

        return `
            <li class="${STATIC_ITEM_CLASS}">
                <span class="rw-autosuggest__result-flights">${text}</span>
            </li>
        `;
    }
}
