import { push } from '@runway/util-history';
import { isMobile } from '@runway/util-detect';
import { trackEvent } from '@runway/util-analytics';

import * as URL from '@runway/util-url';

export const name = 'flight-search';

const IS_SEARCHING_CLASS = 'is-searching';
const LANGUAGE_SWITCHER_CLASS = 'rw-header-language-switch--desktop';
const LANGUAGE_SWITCHER_LINK_CLASS = 'rw-header-button-language-switch';
const EN_LANGUAGE = 'en';

export default class FlightSearch {
    get name() {
        return name;
    }

    constructor(node) {
        this.node = node;
        this.dynamicAreaNode = this.node.querySelector('[data-component="rw-dynamic-area"]');
        this.dynamicAreaInstance = window.SHG.retrieveInstanceFromNode(this.dynamicAreaNode);
        this.endpoint = this.node.querySelector('[data-component="rw-autosuggest"]').getAttribute('data-endpoint');
        this.autosuggestInputNode = this.node.querySelector('[data-component="rw-autosuggest"] > input');
        this.languageSwitcherNodeDesktop = document.querySelector(
            `.${LANGUAGE_SWITCHER_CLASS} .${LANGUAGE_SWITCHER_LINK_CLASS}`
        );
        this.isLoading = false;

        if (!this.dynamicAreaNode) {
            return;
        }

        this.initSubscribes();

        window.addEventListener('popstate', () => this.handlePopState());
    }

    /**
     * Inits all the custom subscribtions
     */
    initSubscribes() {
        this.subscribe('dom:.rw-input-submit__button:click', () => this.handleSearchButtonClick());
        this.subscribe('shg:rw-query-form:_', e => this.handleQueryFormEvent(e));
        this.subscribe('shg:rw-query-form:submit', () => this.handleFocus());
        this.subscribe('shg:rw-autosuggest:show-results', e => this.handleShowResults(e));
        this.subscribe('shg:rw-autosuggest:search', e => this.handleSearch(e));
        this.subscribe('shg:rw-autosuggest:enter-search', e => this.handleEnterSearch(e));
        this.subscribe('shg:rw-autosuggest:choose', e => this.handleChoose(e));
        this.subscribe('shg:rw-autosuggest:hide-results', e => this.handleHideResults(e));
        this.subscribe('shg:rw-dynamic-area:start-load', e => this.handleAreaStartLoad(e));
        this.subscribe('shg:rw-dynamic-area:end-load', e => this.handleAreaEndLoad(e));
        this.subscribe('dom:[name="datetime"]:change', e => this.handleDateChange(e));

        // make sure the endpoint is set to the correct endpoint as showing in the select
        const datetime = this.node.querySelector('[name="datetime"]').value;

        // wait half a second before firing the event because the autosuggest is probably not
        // listening yet
        window.setTimeout(() => {
            this.fireDateChangeEvent(datetime);
        }, 500);
    }

    /**
     * Handles the History changes and if no hash is found on the url reloads
     */
    handlePopState() {
        const hasHashOnLocationHref = (window.location.href.indexOf('#') > -1);

        if (!hasHashOnLocationHref) {
            window.location.reload(false);
        }
    }

    handleQueryFormEvent(customEvent) {
        const { url } = customEvent.detail;

        if (!url) {
            return;
        }

        this.fetchAndRenderResults(url);
    }

    /**
     * Loads new results when facets/query/anything form-ish changes
     * It also update the language switcher URL according the new data loaded
     * @param {Object} event - event details
     * @param {Object} options - additional data
     */
    fetchAndRenderResults(url, options = {}) {
        const { dynamicAreaInstance } = this;
        const { language, currentSelectors, oppositeSelectors } = options;

        dynamicAreaInstance.load(url).then(() => {
            const currentURL = this.languageSwitcherNodeDesktop.getAttribute('href');
            let updatedURL = URL.getUpdatedLastURLParam(currentURL, url);

            if (updatedURL) {
                // This only happens when switching between departures and arrivals
                // It is also needed to translate the word referring the journey type
                if (language && currentSelectors && oppositeSelectors) {
                    // At this point, we want to read and replaced the translated URL,
                    // so we get the language to change instead of the current language
                    const currentKey = language === EN_LANGUAGE ? currentSelectors.nl : currentSelectors.en;
                    const translatedKey = language === EN_LANGUAGE ? oppositeSelectors.nl : oppositeSelectors.en;

                    updatedURL = updatedURL.replace(currentKey, translatedKey);
                }

                this.languageSwitcherNodeDesktop.setAttribute('href', updatedURL);
            }
        });

        this.pushState(url);
    }

    /**
-    * Push on browser history
-    * @param url
-    */
    pushState(url) {
        push(url);
    }

    /**
     * When we start searching using the autosuggest
     * we give the dynamic area a class to indicate the search
     */
    handleShowResults() {
        const { dynamicAreaNode } = this;

        dynamicAreaNode.classList.add(IS_SEARCHING_CLASS);
    }

    /**
     * When the search event fires we check if the search
     * is empty and if so we reload the page without the query.
     * Search event is fired when clicking the cross in the input search
     * or when the user presses enter in the input search.
     * @param {Object} event
     */
    handleSearch(event) {
        const { query } = event.detail;
        const { href, search } = window.location;

        if (query.length || href.indexOf('?') === -1) {
            return;
        }

        const queryString = search.slice(1)
            // split query string into query parameters
            .split('&')
            // filter out 'search'
            .filter(queryParam => queryParam.split('=')[0] !== 'search')
            // put the query string back together
            .join('&');

        this.fetchAndRenderResults(href.replace(/\?.*/, `?${queryString}`));
    }

    handleSearchButtonClick() {
        const query = this.autosuggestInputNode.value;

        trackEvent('flight-info', 'search button', query);
    }

    /**
     * Track searches where a user hit enter in the input field
     * @param {Object} customEvent - The custom shg event containing the tracking data
     */
    handleEnterSearch(customEvent) {
        const { query } = customEvent.detail;

        trackEvent('flight-info', 'search enter', query);
    }

    /**
     * Track searches where a user picked a suggestion from the autosuggest
     * @param {Object} customEvent - The custom shg event containing the tracking data
     */
    handleChoose(customEvent) {
        const { query, searchQuery } = customEvent.detail;

        trackEvent('flight-info', 'choose autosuggest', searchQuery, {
            dimension57: query
        });
    }

    /**
     * If the user clicks the clear button in the search we want to keep focus
     * but if the user submits the form widthout choosing we want to loose focus.
     * This makes sure that we don't get suggestions if the users presses enter before
     * the suggestions appear.
     */
    handleFocus() {
        const inputSearchIsNotEmpty = this.autosuggestInputNode.value.length > 0;

        if (isMobile || inputSearchIsNotEmpty) {
            return this.autosuggestInputNode.blur();
        }

        return this.autosuggestInputNode.focus();
    }

    /**
     * When the XHR request starts add the searching class and
     * set the state of loading
     */
    handleAreaStartLoad() {
        this.isLoading = true;
        this.dynamicAreaNode.classList.add(IS_SEARCHING_CLASS);
    }

    /**
     * When the content is appended we remove the is searching class
     * and add the done searching class to the dynamic area.
     */
    handleAreaEndLoad() {
        const { dynamicAreaNode } = this;

        this.isLoading = false;

        dynamicAreaNode.classList.remove(IS_SEARCHING_CLASS);
    }

    /**
     * Removes the is searching class if the dynamic area is not loading
     */
    handleHideResults() {
        if (this.isLoading) {
            return;
        }

        this.dynamicAreaNode.classList.remove(IS_SEARCHING_CLASS);
    }

    handleDateChange(inputEvent) {
        const datetime = inputEvent.target.value;

        this.fireDateChangeEvent(datetime);
    }

    fireDateChangeEvent(datetime) {
        if (!datetime) {
            return;
        }

        const { endpoint } = this;
        const newEndpoint = `${endpoint}&datetime=${window.encodeURIComponent(datetime)}`;

        this.fireEvent({
            type: 'autosuggest-endpoint-change',
            endpoint: newEndpoint
        });
    }
}
