import { getCookie, setCookie } from '@runway/util-cookie';
import { trackEvent } from '@runway/util-analytics';

const FLIGHT_COOKIE = 'flight';
const FLIGHT_COOKIE_DISCARD_LENGTH = 17;

export default class ChinaFlightsCookie {
    get name() {
        return 'china-flights-cookie';
    }

    constructor(node) {
        this.node = node;

        this.initClassProperties();
        this.initListeners();
        this.setActiveStyle();
    }

    refreshPage() {
        window.location.reload();
    }

    clearFlight() {
        document.cookie = `${FLIGHT_COOKIE}=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/`;

        this.selectedFlight = '';
        this.flightSearchInput.value = '';
    }

    setActiveStyle() {
        const { flightCookie, linkText } = this;
        const flightUrl = flightCookie.replace('flights/','');

        if (flightCookie) {
            this.selectedFlight = flightCookie.slice(FLIGHT_COOKIE_DISCARD_LENGTH - flightCookie.length);
            this.flightLink.href = "/en/departures/flight/" + flightUrl;
            this.flightLinkText.textContent = `${linkText} ${this.selectedFlight}`;
            this.flightLink.removeAttribute("hidden"); 
        }
    }

    handleFlightAutosuggestChange(event) {
        this.clearFlight();

        const { query: flightIdentifier, searchQuery } = event.detail;
        
        trackEvent('flight-info', 'choose autosuggest', searchQuery, {
            dimension57: searchQuery
        });

        setCookie(FLIGHT_COOKIE, `flights/${flightIdentifier}`, {
            path: '/' 
        });

        this.chinaFlightCookie();
        this.refreshPage();
    }

    handleDateChange(event) {
        const date = event.target.value;
        const newEndpoint = this.flightSuggestionsEndpoint.replace(
            /datetime=[0-9-]+/,
            `datetime=${window.encodeURIComponent(date)}`
        );

        this.fireEvent({
            type: 'autosuggest-endpoint-change',
            endpoint: newEndpoint
        });
    }

    initClassProperties() {
        this.selectedFlight = '';
        this.flightSearch = document.querySelector('.flights-search--china');
        this.flightSuggestionsEndpoint = document.querySelector('[data-flight-search]').getAttribute('data-endpoint');
        this.flightLink = document.getElementById('flight-link');
        this.flightLinkText = document.getElementById('flight-link-text');
        this.linkText = this.flightLinkText.textContent;
    }

    initListeners() {
        this.chinaFlightCookie();
        this.subscribe('shg:rw-autosuggest:choose', e => this.handleFlightAutosuggestChange(e));
        this.subscribe('dom:[data-fly-date-dropdown]:change', e => this.handleDateChange(e));
    }

    chinaFlightCookie() {
        const { flightCookie } = this;

        if (flightCookie) {
            this.selectedFlight = flightCookie.slice(FLIGHT_COOKIE_DISCARD_LENGTH - flightCookie.length);
        }
    }

    get flightCookie() {
        return getCookie(FLIGHT_COOKIE);
    }

    get flightSearchInput() {
        return document.getElementById('flight-search-china-autosuggest');
    }
}
