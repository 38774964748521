/**
 * This class is responsible for connecting to the actual waiting times API.
 * This API describes how long the queues for security are.
 * At the moment (Q4 2022) both of the APIs are proxied by us so cloudflare is in front of them and the real APIs won't crash.
 *
 * The actual waiting times has 2 endpoints we use:
 * 1. Actual waiting times for extended queues: currentpredictionswithdetails/API_all_extended_queue_predictions
 * 2. Actual waiting times only in the "maze" at security: currentpredictionswithdetails/API_all_current_predictions
 */

export class ActualWaitingTimesClient {
    constructor(actualWaitingTimesBaseUrl) {
        this.actualWaitingTimesBaseUrl = actualWaitingTimesBaseUrl;
        this.ALL_CURRENT_PREDICTIONS = "currentpredictionswithdetails/API_all_current_predictions";
        this.TOTAL_WAITING_TIMES_ENDPOINT = "security-filters";
        this.WAITING_TIME_AT_DISPLAY_ENDPOINT = "displays/:display";
    }

    async fetchTotalWaitingTimeForSecurityFilter(securityFilter) {
        // Ensure correct security filter key is used.
        securityFilter = securityFilter.substring(0, 3).toUpperCase();

        return fetch(this.actualWaitingTimesBaseUrl + this.TOTAL_WAITING_TIMES_ENDPOINT)
            .then(this.handleErrors)
            .then(response => response.json())
            .then(data => {
                // eslint-disable-next-line no-prototype-builtins
                if (! data.hasOwnProperty(securityFilter)) {
                    console.log(`Security filter '${securityFilter}' is not available in the response.`);

                    return null;
                }

                return Math.ceil(data[securityFilter].waitTimeInSeconds / 60);
            });
    }

    async fetchWaitingTimeAtDisplay(display) {
        const path = this.WAITING_TIME_AT_DISPLAY_ENDPOINT.replace(':display', display);
        const url = this.actualWaitingTimesBaseUrl + path;

        return fetch(url)
            .then(this.handleErrors)
            .then(response => response.json())
            .then(data => {
                // eslint-disable-next-line no-prototype-builtins
                if (! data || ! data.hasOwnProperty('waitTimeInSeconds')) {
                    console.log(`Display '${display}' is not available in the displays api.`);

                    return null;
                }

                return {
                    hasExtendedQueue: data.isQueuePresent,
                    waitingTime: Math.ceil(data.waitTimeInSeconds / 60),
                };
            })
            .catch(error => {
                console.log(`Fetch error for "${path}"`);
                console.log(error);

                return null;
            });
    }

    /**
     * Fetch the extended queue indicators for a security filter area
     * @param securityFilter Example: VF1
     * @return string|null
     */
    async fetchExtendedQueueEntryPointIndicator(securityFilter) {
        return fetch(this.actualWaitingTimesBaseUrl + this.TOTAL_WAITING_TIMES_ENDPOINT)
            .then(this.handleErrors)
            .then(response => response.json())
            .then(data => {
                // eslint-disable-next-line no-prototype-builtins
                if (! data.hasOwnProperty(securityFilter) || ! data[securityFilter].hasOwnProperty('extendedQueue')) {
                    return null;
                }

                return data[securityFilter]['extendedQueue']['entryPoint'];
            }).catch(error => {
                console.log(error);

                return null;
            });
    }

    /**
     * @param securityFilterArea Example: VF1_economy
     * @returns {Promise<number | number>}
     */
    async fetchWaitingTimeAtSecurity(securityFilterArea) {
        return fetch(this.actualWaitingTimesBaseUrl + this.ALL_CURRENT_PREDICTIONS)
            .then(this.handleErrors)
            .then(response => response.json())
            .then(data => {
                // eslint-disable-next-line no-prototype-builtins
                if (! data.hasOwnProperty('predictions')) {
                    throw new Error('Missing data. There was a problem fetching data from the API.');
                }

                const queueData = data.predictions.find(
                    item => item?.resultId === securityFilterArea
                );

                if (! queueData) {
                    throw new Error(`Missing data. The given "${securityFilterArea}" area is not found in the result set (maybe a misspelling?)`);
                }

                const seconds = queueData?.details?.timeInSeconds || 0;

                return Math.ceil(seconds / 60);
            })
            .catch(error => {
                console.log(error);

                return 0;
            });
    }

    handleErrors(response) {
        if (! response.ok) {
            console.log(response);
            throw Error(response.statusText);
        }

        return response;
    }
}
