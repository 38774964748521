import * as Dom from '@runway/util-dom';

const SRE_MAP_POPUP_DEFAULT_OFFSET = 20;

export const name = 'sre-mapbox-poi';

export default class SreMapboxPoi {
    get name() {
        return name;
    }

    get marker() {
        return this._marker;
    }

    set marker(marker) {
        this._marker = marker;
    }

    constructor(id, latLng, popupData, className) {
        this.createMarkerForProperty(latLng, popupData, (className || ''), [
            {
                name: 'data-id',
                value: id
            }
        ]);
    }

    createMarkerForProperty(latLng, popupData, className, attributes) {
        const config = {
            tag: 'div',
            className,
            attributes
        };
        const markerNode = Dom.create(config);

        this.marker = new window.mapboxgl.Marker(markerNode);
        this.marker.setLngLat(latLng);

        if ((popupData && popupData.html)) {
            const popup = new window.mapboxgl.Popup({
                offset: popupData.offset || SRE_MAP_POPUP_DEFAULT_OFFSET
            });

            popup.setHTML(popupData.html);
            this.marker.setPopup(popup);
        }
    }
}
