import { Chart, registerables } from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import sdsTokens from "@schiphol/sds-tokens/lib/web/pax/tokens";
import { trackEvent } from "@runway/util-analytics";
import ForecastedWaitingTimesClient from "./forecasted-waiting-times-client";
import { ActualWaitingTimesClient } from "./actual-waiting-times-client";
import { WhereIsMyQueue } from "./where-is-my-queue";

/**
 * Is the current time within the boarding time range of 3 hours before boarding to 1 hour after?
 */
export const currentTimeIsInBoardingTimeRange = (boardingTime) => {
    const now = new Date();
    const hoursDiff = (boardingTime - now) / 36e5;

    return hoursDiff >= -1 && hoursDiff <= 4;
};

export const findTime = (time, waitingTimes) => {
    const [nowHour, nowMinute] = time.split(":");
    const isAfterHalfHour = nowMinute >= "30";

    // return waiting time value for current time
    return waitingTimes.find(({ hour: label }) => {
        const [h, m] = label.split(":");

        return h === nowHour && (isAfterHalfHour ? m === "30" : m === "00");
    });
};

/**
 * We always show light blue bar chart color, but for the current hour we show a darker blue color.
 */
export const createBackgroundColors = (boardingTime, waitingTimes) => {
    const colors = Array(10).fill(sdsTokens.sdsPaxColorAccent150);

    if (!currentTimeIsInBoardingTimeRange(boardingTime)) {
        return colors;
    }

    const now = new Date().toLocaleTimeString("nl", {
        timeZone: "Europe/Amsterdam",
        hour: "numeric",
        minute: "numeric",
        hour12: false,
    });
    const indexOfNow = waitingTimes.indexOf(findTime(now, waitingTimes));

    // Set the color for the "now" bar
    if (indexOfNow >= 0) {
        colors[indexOfNow] = sdsTokens.sdsPaxColorAccent50;
    }

    return colors;
};

const setupWaitingTimesChart = async (
    showChart,
    securityFilter,
    boardingTimeString,
    actualWaitingTimesBaseUrl,
    forecastedWaitingTimesBaseUrl,
    flightCheckInCounter,
    departureCrowdednessLevel, // Note that the departureCrowdednessLevel value comes from the Contentful API, which already filters between the available ranges from Contentful.
    useFallbackCrowdednessLevel, // In case waiting times are incorrect, we can still control it via Contentful to show the default Crowdedness Level
    locale
) => {
    Chart.register(...registerables, annotationPlugin);

    const pluralMinsByLocale = locale === 'nl' ? 'min' : 'mins';

    const createTableRow = (
        values,
        parentElement,
        element,
        additionalText,
        attribute
    ) => {
        const tableRow = document.createElement("tr");

        return values.forEach((value) => {
            const tableElement = document.createElement(element);
            const textNode = document.createTextNode(
                `${value} ${additionalText}`
            );

            if (attribute.name === "datetime") {
                const timeElement = document.createElement("time");

                timeElement.setAttribute(
                    attribute.name,
                    attribute.value || value
                );
                timeElement.appendChild(textNode);
                tableElement.appendChild(timeElement);
            } else {
                tableElement.setAttribute(
                    attribute.name,
                    attribute.value || value
                );
                tableElement.appendChild(textNode);
            }

            tableRow.appendChild(tableElement);

            return parentElement.appendChild(tableRow);
        });
    };

    const createAccessibilityTable = (tableId, labels, values) => {
        const accessibleTable = document.querySelector(tableId);
        const tableBody = document.createElement("tbody");

        createTableRow(labels, tableBody, "th", "hrs.", {
            name: "datetime",
            value: null,
        });
        createTableRow(values, tableBody, "td", "min.", {
            name: "scope",
            value: "col",
        });

        return accessibleTable.appendChild(tableBody);
    };

    const populateEstimatedWaitingTimesSummary = (
        waitingTimes,
        actualWaitingTime
    ) => {
        // This is a failsafe so anyone with access to contentful translations and Github deployments will be able to stop rendering the chart if the data is very broken.
        // This was necessary March 10th 2023, when the security staff schedule was not updated for a few days and the resulting forecast was way too high.
        if (!showChart) {
            return;
        }

        let minValue, maxValue;
        let infinity = false;

        if (actualWaitingTime !== null && actualWaitingTime !== undefined) {
            // round to 5 or 10 minutes interval
            const minutesInterval = actualWaitingTime >= 60 ? 10 : 5;

            minValue =
                Math.floor(actualWaitingTime / minutesInterval) *
                minutesInterval;
            maxValue = minValue + minutesInterval;
        } else {
            if (!waitingTimes || waitingTimes.length === 0) {
                return;
            }

            const values = waitingTimes.map((item) => item.waitingTime);

            minValue = Math.min(...values);
            minValue = minValue > 90 ? "90+" : minValue;

            maxValue = Math.max(...values);
            infinity = maxValue > 90;
            maxValue = infinity ? "90+" : maxValue;
        }

        const titleElement = document.querySelector(
            '[data-itinerary="waiting-time-summary"]'
        );
        const waitingTimeStrongTag = document.createElement("strong");

        let waitingTimeText;

        if (minValue === maxValue) {
            waitingTimeText = (infinity ? "" : "±") + maxValue + " " + pluralMinsByLocale;
        } else if (maxValue > 90) {
            waitingTimeText = "90+ " + pluralMinsByLocale;
        } else {
            waitingTimeText = minValue + " - " + maxValue + " " + pluralMinsByLocale;
        }

        const waitingTimeTextNode = document.createTextNode(waitingTimeText);

        if (titleElement) {
            waitingTimeStrongTag.appendChild(waitingTimeTextNode);
            titleElement.appendChild(waitingTimeStrongTag);

            trackEvent(
                "flight-info",
                "security waitingtimes impression",
                waitingTimeText
            );
        }

        // replace the generic crowdedness diagram with the estimated waiting time range.
        document
            .querySelector('[data-itinerary="waiting-time-summary"]')
            .classList.remove("hidden");
        document
            .querySelector('[data-itinerary="crowdedness-diagram"]')
            .classList.add("hidden");
    };

    /**
     * Show crowdedness based on waiting times or else the fallback of contentful
     */
    const printCrowdednessInformation = (waitingTimes = [], useFallbackCrowdednessLevel) => {
        let crowdedness = "normal";

        // If we have waiting times, base the crowdedness on that
        // If we don't want to show the chart because the waiting times data is broken, don't use that data for the crowdedness either.
        if (waitingTimes.length > 0 && showChart && ! useFallbackCrowdednessLevel) {
            const values = waitingTimes.map((item) =>
                parseInt(item.waitingTime)
            );
            const maxWaitingTime = Math.max(...values);

            if (maxWaitingTime > 15 && maxWaitingTime <= 90) {
                crowdedness = "busy";
            } else if (maxWaitingTime > 90) {
                crowdedness = "peak";
            }
            // If we have no waiting times, use the crowdedness value from contentful
        } else if (departureCrowdednessLevel) {
            crowdedness = departureCrowdednessLevel;
        } // else we keep the default "normal"

        toggleCrowdednessElements(crowdedness);

        document
            .querySelector('[data-crowdedness="spinner"]')
            .classList.remove("active");
    };

    const crowdednessTextElement = (textType, crowdednessLevel) => {
        return document.querySelector(
            `[data-crowdedness-${textType}="${crowdednessLevel}"]`
        );
    };

    const updateCrowdednessIcons = (crowdedness) => {
        const crowdIndicators = document.querySelectorAll(
            ".crowd-indicator--with-icon"
        );

        return crowdIndicators.forEach(
            (indicator) => (indicator.dataset.crowd = crowdedness)
        );
    };

    const toggleCrowdednessElements = (crowdedness) => {
        updateCrowdednessIcons(crowdedness);

        crowdednessTextElement("label", crowdedness).classList.toggle("active");
        crowdednessTextElement("security-title", crowdedness).classList.toggle(
            "active"
        );
        crowdednessTextElement("advise", crowdedness).classList.toggle(
            "active"
        );

        if (flightCheckInCounter === undefined) {
            crowdednessTextElement("tip", crowdedness).classList.toggle(
                "active"
            );
        } else if (flightCheckInCounter === null) {
            crowdednessTextElement(
                "tip",
                `${crowdedness}-alt`
            ).classList.toggle("active");
        } else {
            crowdednessTextElement("tip", crowdedness)?.classList.toggle(
                "active"
            );
        }
    };

    /**
     * This function processes the fetched forecasted waiting times data.
     * - If there is not enough data, the simple puppets diagram with crowdedness info from Contentful is shown
     * - else
     *      - Draw the chart with accessibility table.
     *      - Display crowdedness info and waiting times text based on the forecasted waiting times
     *        (Currently this is not implemented ideally, because the translations are fetched in the backend.
     *        This means we have to render all 3 crowdedness versions (normal, busy, peak) initially hidden and
     *        toggle 'active' and 'hidden' classes to show the relevant version.)
     *
     * @param {Date} boardingTime is required to determine the current hour is in the chart range (and not a different day)
     * @param {Array} waitingTimes is array of objects like: {hour: '16', waitingTime: '90'}.
     */
    const renderChartAndTable = (boardingTime, waitingTimes) => {
        // This is a failsafe so anyone with access to contentful(?) should theoretically be able to stop rendering the chart if the data is very broken.
        if (!showChart) {
            return;
        }

        if (!waitingTimes || waitingTimes.length < 5) {
            // only draw chart when we have all five values for it. You will have less when
            // you are looking an hour beyond the end of the data set.
            return;
        }

        const labels = waitingTimes.map(({ hour }) => hour);
        const values = waitingTimes.map(({ waitingTime }) => waitingTime);

        const ctx = document
            .querySelector('[data-itinerary="chart"]')
            .getContext("2d");

        new Chart(ctx, {
            type: "bar",
            data: {
                labels: labels,
                datasets: [
                    {
                        data: values,
                        backgroundColor: createBackgroundColors(
                            boardingTime,
                            waitingTimes
                        ),
                        borderColor: "none",
                        borderWidth: 0,
                        borderRadius: 10,
                        // by making the category full width, we can precisely set the bar width
                        categoryPercentage: 1,
                        barPercentage: 0.75,
                        barThickness: 9,
                    },
                ],
            },
            options: {
                events: [], // disable hover effect
                responsive: true, // chart will adjust to parent container,
                animation: {
                    duration: 0, // disable animation
                },
                scales: {
                    x: {
                        grid: {
                            display: false,
                        },
                        ticks: {
                            color: sdsTokens.sdsPaxColorNeutral50,
                            callback: function (tickValue) {
                                const label = this.getLabelForValue(tickValue);

                                // Only return label for full hour
                                if (!label.includes("30")) {
                                    return label.substring(0, 2);
                                }

                                return null;
                            },
                        },
                    },
                    y: {
                        beginAtZero: true,
                        max: 121,
                        ticks: {
                            stepSize: 30,
                            color: sdsTokens.sdsPaxColorNeutral50,
                        },
                    },
                },
                plugins: {
                    legend: false,
                    tooltip: false,
                },
            },
        });

        createAccessibilityTable(
            '[data-itinerary="accessible-table"]',
            labels,
            values
        );
        showWaitingTimesChart();
    };

    const showWaitingTimesChart = () => {
        const detailForecastText = document.querySelector(
            '[data-itinerary="detailed-forecast-text"]'
        );

        if (detailForecastText) {
            detailForecastText.classList.add("hidden");
        }

        document
            .querySelector('[data-itinerary="chart-wrapper"]')
            .classList.remove("hidden");
    };

    /**
     * If we have an actual waiting time, use that instead of the estimated waiting time for the current hour.
     */
    const addActualWaitingTime = async (
        boardingTime,
        waitingTimes,
        actualWaitingTimesClient,
        securityFilter
    ) => {
        // Ensure that the current time is in the time range we display in the chart, so we don't make an API call for nothing.
        if (!currentTimeIsInBoardingTimeRange(boardingTime)) {
            return;
        }

        const actualWaitingTime =
            await actualWaitingTimesClient.fetchTotalWaitingTimeForSecurityFilter(
                securityFilter
            );

        if (actualWaitingTime !== null) {
            // replace the "Now" text if we have actual waiting time
            document
                .querySelector('[data-waiting-time-label="estimated"]')
                .classList.add("hidden");
            document
                .querySelector('[data-waiting-time-label="actual"]')
                .classList.remove("hidden");

            // Replace the current hour in the forecast with the actual waiting time
            const now = new Date().toLocaleTimeString("nl", {
                timeZone: "Europe/Amsterdam",
                hour: "numeric",
                minute: "numeric",
                hour12: false,
            });
            const forecast = findTime(now, waitingTimes);

            if (forecast) {
                forecast.waitingTime = Math.max(5, actualWaitingTime); // always show bar in the graph even for value of 0 #noToothlessGraphs
            }
        }

        return actualWaitingTime;
    };

    const forecastedWaitingTimesClient = new ForecastedWaitingTimesClient(
        forecastedWaitingTimesBaseUrl
    );
    const actualWaitingTimesClient = new ActualWaitingTimesClient(
        actualWaitingTimesBaseUrl
    );

    const boardingTime = new Date(boardingTimeString);
    const waitingTimes =
        await forecastedWaitingTimesClient.fetchForecastedWaitingTimesAroundBoardingTime(
            securityFilter,
            boardingTime
        );
    const actualWaitingTime = await addActualWaitingTime(
        boardingTime,
        waitingTimes,
        actualWaitingTimesClient,
        securityFilter
    );

    printCrowdednessInformation(waitingTimes, useFallbackCrowdednessLevel);
    populateEstimatedWaitingTimesSummary(waitingTimes, actualWaitingTime);

    const wmq = new WhereIsMyQueue(
        actualWaitingTimesClient,
        securityFilter,
        boardingTime
    );

    if (await wmq.shouldShowWhereIsMyQueue()) {
        wmq.render(boardingTime);

        return;
    }

    renderChartAndTable(boardingTime, waitingTimes);
};

window.setupWaitingTimesChart = setupWaitingTimesChart;

export default setupWaitingTimesChart;
