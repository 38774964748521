//
// Ecommerce tracking configuration
// ================================
//
// Configure kinds of ecommerce tracking structures here
// The objects in the arrays represent an options to read
// `name` is the key in the object that will be sent to the dataLayer
//
// fill with either `attribute` or `value` or `type=TYPE_NODES + config`
//
// `attribute` refers to the name of a data attribute: `data-analytics-${name}`
// `type` (optional) can be any of TYPE_BOOL, TYPE_FLOAT or TYPE_INT; which will be parsed as such
//
// `value` refers to either a literal value
// if the `value` is filles with an array, it will be parsed as an ecommerce tracking configuratiuon
// the result of that operation will be an object
//
// `type=TYPE_NODES` will parse the node OR childnodes with a specified `config`
// `config` refers to an array with a ecommerce tracking configuration
// `childSelector` selects childnodes by `data-analytics-${childSelector}` attributes
//

import { TYPE_NODES } from '@runway/util-analytics';

export const ecommerce = {};

// these defaults can be overriden
// specify the same keys in a specific ecommerce configurations
ecommerce.defaults = [
    {
        name: 'eventCategory',
        attribute: 'event-category' 
    },
    {
        name: 'eventAction',
        attribute: 'event-action' 
    },
    {
        name: 'eventLabel',
        attribute: 'event-label' 
    },
    {
        name: 'eventEnvironment',
        value: 'new website' 
    },
    {
        name: 'eventAgentCode',
        value: 'WEB' 
    }
];

//
// Messages For Travellers
//

ecommerce['messages-for-travellers-detail-page'] = [
    {
        name: 'event',
        value: 'GAEvent' 
    },
    {
        name: 'eventCategory',
        value: 'tier' 
    },
    {
        name: 'eventAction',
        value: 'tier_detail' 
    },
    {
        name: 'dimension59',
        attribute: 'tier-message-id' 
    },
    {
        name: 'dimension61',
        attribute: 'tier-updated-at' 
    }
];

ecommerce['messages-for-travellers-attention-item'] = [
    {
        name: 'event',
        value: 'EnhancedEvent' 
    },
    {
        name: 'eventCategory',
        value: 'tier' 
    },
    {
        name: 'eventAction',
        value: 'tier_impression' 
    },
    {
        name: 'ecommerce',
        value: [
            {
                name: 'promoView',
                value: [
                    {
                        name: 'promotions',
                        type: TYPE_NODES,
                        config: [
                            {
                                name: 'id',
                                attribute: 'tier-message-id' 
                            },
                            {
                                name: 'name',
                                value: 'tier attention' 
                            },
                            {
                                name: 'creative',
                                attribute: 'tier-number' 
                            },
                            {
                                name: 'position',
                                attribute: 'follow-number' 
                            }
                        ]
                    }
                ]
            }
        ]
    }
];

ecommerce['messages-for-travellers-attention-item-click'] = [
    {
        name: 'event',
        value: 'EnhancedEvent' 
    },
    {
        name: 'eventCategory',
        value: 'tier' 
    },
    {
        name: 'eventAction',
        value: 'tier_click' 
    },
    {
        name: 'ecommerce',
        value: [
            {
                name: 'promoClick',
                value: [
                    {
                        name: 'promotions',
                        type: TYPE_NODES,
                        config: [
                            {
                                name: 'id',
                                attribute: 'tier-message-id' 
                            },
                            {
                                name: 'name',
                                value: 'tier attention' 
                            },
                            {
                                name: 'creative',
                                attribute: 'tier-number' 
                            },
                            {
                                name: 'position',
                                attribute: 'follow-number' 
                            }
                        ]
                    }
                ]
            }
        ]
    }
];

//
// SMS Notifications
//

ecommerce['impression-SMS-notification'] = [
    {
        name: 'event',
        value: 'GAEvent' 
    },
    {
        name: 'eventEnvironment',
        value: 'new website' 
    }
];

ecommerce['impression-SMS-button'] = [
    {
        name: 'event',
        value: 'GAEvent' 
    },
    {
        name: 'eventEnvironment',
        value: 'new website' 
    }
];

// Homepage service notification
ecommerce['Service Notification'] = [
    {
        name: 'event',
        value: 'GAEvent' 
    },
    {
        name: 'eventNonInteraction',
        value: true 
    }
];
