function getOffsetTop(element) {
    let offsetTop = 0;

    while (element) {
        offsetTop += element.offsetTop;
        element = element.offsetParent;
    }

    return offsetTop;
}

export default function fixOnScroll(element, className, threshold = 0) {
    const headerOffsetTop = getOffsetTop(element);

    window.addEventListener('scroll', () => {
        const windowScrollPosition = window.pageYOffset;

        const pageIsScrolledBellowLiveBlog = windowScrollPosition < headerOffsetTop - threshold;

        if (pageIsScrolledBellowLiveBlog) {
            element.classList.remove(className);

            return;
        }

        element.classList.add(className);
    });
}
