/**
 * This class is responsible for rendering an SVG that shows the user where the extended
 * queue starts if there is a very long queue.
 * It uses the ActualWaitingTimesClient to fetch the required data.
 */
export class WhereIsMyQueue {
    constructor(actualWaitingTimesClient, securityFilter, boardingTime) {
        this.initialised = false;
        this.actualWaitingTimesClient = actualWaitingTimesClient;
        this.securityFilter = securityFilter;
        this.boardingTime = boardingTime;
        this.queueEntryPoint = null;
    }

    async init() {
        this.initialised = true;

        if (this.securityFilter !== 'VF1' || ! this.isBoardingTimeWithin4hours(this.boardingTime)) {
            return;
        }

        const entryPointIndicator = await this.fetchEntryPointIndicator();

        if (entryPointIndicator === null) {
            return;
        }

        const QUEUE_ENTRY_POINT_INDICATOR_MAP = {
            'vf1_extended_queue_info_emergency_exit_indicator': '2',
            'vf1_extended_queue_info_exit_vh1a_indicator': '3',
            'vf1_extended_queue_info_enter_vh1a_indicator': '5',
        };

        if (!(entryPointIndicator in QUEUE_ENTRY_POINT_INDICATOR_MAP)) {
            throw new Error(`Entry point "${entryPointIndicator}" is not mapped`);
        }

        this.queueEntryPoint = QUEUE_ENTRY_POINT_INDICATOR_MAP[entryPointIndicator];
    }

    isBoardingTimeWithin4hours(boardingTime) {
        const hoursDiff = (boardingTime - new Date()) / 36e5;

        return hoursDiff > 0 && hoursDiff < 4;
    }

    async shouldShowWhereIsMyQueue() {
        if (! this.initialised) {
            await this.init();
        }

        return this.queueEntryPoint !== null;
    }

    async render() {
        if (! await this.shouldShowWhereIsMyQueue()) {
            return;
        }

        document.querySelector(".wmq-starting-point--" + this.queueEntryPoint)?.classList.remove("hidden");
        document.querySelector(".itinerary__wmq-text-" + this.queueEntryPoint)?.classList.remove("hidden");
        document.querySelector(".itinerary__wmq-security-header-content")?.classList.remove("hidden");
        document.querySelector(".itinerary__security-header-content")?.classList.add("hidden");
    }

    async fetchEntryPointIndicator() {
        return this.actualWaitingTimesClient.fetchExtendedQueueEntryPointIndicator(this.securityFilter);
    }
}
