import * as Dom from '@runway/util-dom';
import { name as compositionName } from './sre-map';

const SRE_CARD_CLASS = 'sre-map-property-card';
const SELECTED_CLASS = 'is-selected';

export const name = 'sre-map-tab';

export default class SreMapTab {
    get name() {
        return name;
    }

    constructor(node) {
        this.node = node;

        this.cacheSelectors();
        this.setupSubscriptions();
    }

    cacheSelectors() {
        this.propertyCards = this.node.querySelectorAll(`.${SRE_CARD_CLASS}`);
    }

    setupSubscriptions() {
        this.subscribe(
            'shg:sre-mapbox:marker-click',
            e => this.handleMarkerClicked(e),
            {
                tree: Dom.closest(this.node, `.${compositionName}`) 
            }
        );
    }

    updateSelectedProperty(id) {
        this.deselectProperty();

        if (id) {
            this.selectProperty(id);
        }

        this.scrollPropertyIntoView(this.selectedProperty);
    }

    deselectProperty() {
        if (this.selectedProperty) {
            this.selectedProperty.classList.remove(SELECTED_CLASS);
            this.selectedProperty = null;
        }
    }

    selectProperty(id) {
        Array.from(this.propertyCards).forEach(property => {
            if (Dom.getDataFromElement(property).propertyId === id) {
                this.selectedProperty = property;
                this.selectedProperty.classList.add(SELECTED_CLASS);
            }
        });
    }

    scrollPropertyIntoView(property) {
        // NOTE: with viewport smaller then 768 this tab has no height,
        // in this case the whole document scrolls to the selected property.
        if (window.innerWidth < 768) {
            property.scrollIntoView();
        } else {
            this.node.scrollTop = property.offsetTop - this.node.offsetTop;
        }
    }

    handleMarkerClicked(event) {
        this.updateSelectedProperty(event.detail.data.id);
    }
}
