/**
 * When the page scrolls beyond 'initialY'
 * apply the STICK_TO_TOP class to the node
 * which should stick to the top of the page
 * after it becomes invisible
 */
export default function onScroll(initialY) {
    const STICK_TO_TOP = 'stick-to-top';
    const CHECK_AVAILABILITY_HEADER_SELECTOR = '[data-scroll-handler="sticky-header"]';
    const CHECK_AVAILABILITY_BUTTON_SELECTOR = '.btn';

    const nodeToMakeSticky = document.querySelector(CHECK_AVAILABILITY_HEADER_SELECTOR);
    const buttonForTracking = nodeToMakeSticky.querySelectorAll(CHECK_AVAILABILITY_BUTTON_SELECTOR);
    const { pageYOffset: scrollTop } = window;

    if (abortSticky()) {
        return;
    }

    let stickyAttr;

    if (scrollTop > initialY) {
        nodeToMakeSticky.classList.add(STICK_TO_TOP);
        stickyAttr = 'click sticky';
    } else {
        nodeToMakeSticky.classList.remove(STICK_TO_TOP);
        stickyAttr = 'click';
    }

    if (buttonForTracking) {
        Array.from(buttonForTracking).forEach(button => button.setAttribute('data-analytics-action', stickyAttr));
    }
}

/**
 * Should we restrict the 'stickiness' of the header for a particular width
 *
 * return bool Should abort with sticky behaviour
 */
function abortSticky() {
    if (window.matchMedia('(max-width: 64em)').matches) {
        return true;
    }

    return false;
}
