// TODO refactor me please!!!!
import AbstractFormComponent from '@runway/abstract-form-item';

export const name = 'bank-account';

export default class BankAccount extends AbstractFormComponent {
    get name() {
        return name;
    }

    /**
     * instantiates an bank-account
     * @param {Object} node - node to instantiate
     */
    constructor(node) {
        super(node);

        this.ibanNode = this.node.querySelector('[data-component=rw-input-element-iban]');

        if (this.ibanNode) {
            this.ibanInstance = window.SHG.retrieveInstanceFromNode(this.ibanNode);
        }

        this.bicNode = this.node.querySelector('[data-component=rw-input-element]');

        if (this.bicNode) {
            this.bicInstance = window.SHG.retrieveInstanceFromNode(this.bicNode);
        }
        // initial check on page load (in case there are already data filled in)
        this.checkBicRequired(true);
        this.subscribe('dom:[data-component=rw-input-element-iban] input:change', () => this.checkBicRequired());
    }

    /**
     * Check if the IBAN is empty
     * @return Boolean
     */
    isIbanEmpty() {
        if (!this.ibanInstance) {
            return false;
        }

        return this.ibanInstance.inputNode.value === '';
    }

    /**
     * Check if the bank account is valid
     * @return Boolean
     */
    isValid() {
        if (this.isIbanEmpty()) {
            if (this.ibanNode && this.ibanNode.hasAttribute('data-required')) {
                this.setIbanError();

                return false;
            }

            if (this.bicInstance && this.bicInstance.inputNode.value !== '') {
                this.setIbanError();

                return false;
            }

            return true;
        }

        // In the input-element-iban we use an external lib 'iban' to check if it is valid IBAN number
        const validIban = this.validateIban();

        return this.isValidBic(validIban);
    }

    /**
     * Checks if the IBAN is valid and if so calls cleanError
     * @return Boolean
     */
    validateIban() {
        const validIban = this.ibanInstance.isValid();

        if (validIban) {
            this.ibanInstance.cleanError();
        }

        return validIban;
    }

    /**
     * Sets the error for the IBAN field
     */
    setIbanError() {
        const errorMsg = this.ibanNode.getAttribute('data-message_required');

        this.ibanInstance.showError(errorMsg);
    }

    /**
     * Check if the BIC is valid
     * @param  {Boolean} validIban
     * @return Boolean
     */
    isValidBic(validIban) {
        let validBic = true;

        if (this.bicInstance) {
            validBic = this.bicInstance.isValid();
        }

        if (!validIban) {
            return validBic;
        }

        const { value } = this.ibanInstance.inputNode;

        if (value.substring(0, 2) !== 'NL') {
            return validBic;
        }

        return validIban;
    }

    // this component doesn't have its own errors so far
    showError() {}

    /**
     * if the IBAN is required
     * -- and it is not a valid IBAN -> the BIC is required
     * -- and it is a valid IBAN
     * --- it is a Dutch IBAN -> the BIC is NOT required
     * --- it is NOT a Dutch IBAN -> the BIC is required
     * @param  {Boolean} [onload=false] Default false but when loaded with filled in input it should be true
     */
    checkBicRequired(onload = false) {
        // set default value
        this.toggleBicRequired(false, onload);

        if (this.isIbanEmpty()) {
            return;
        }

        if (!this.ibanInstance) {
            return;
        }
        const validIban = this.validateIban();

        if (validIban) {
            const { value } = this.ibanInstance.inputNode;

            if (value.substring(0, 2) !== 'NL') {
                this.toggleBicRequired(true, onload);
            }
        } else {
            this.toggleBicRequired(true, onload);
        }
    }

    /**
     * Toggle the required attribute for the BIC and IBAN input
     * @param  {Boolean}  required
     * @param  {Boolean} [onload=false]
     */
    toggleBicRequired(required, onload = false) {
        if (!this.bicNode) {
            return;
        }

        if (required) {
            this.bicNode.setAttribute('data-required', 'true');
        } else {
            this.bicNode.removeAttribute('data-required');

            if (!onload && this.bicInstance) {
                this.bicInstance.cleanError();
            }
        }
    }
}
