import { trackEvent } from '@runway/util-analytics';

export const name = 'flight-search-widget';

const DIRECTION_SELECTOR = '[name="direction"]';
const DATETIME_SELECTOR = '[name="datetime"]';
const FLIGHT_SEARCH_HERO_SUBMIT_BUTTON = '#flight-search-widget-submit';

export default class FlightSearchWidget {
    get name() {
        return name;
    }

    constructor(node) {
        this.node = node;
        // make sure direction is also set correctly after navigating back
        this.direction = node.querySelector(`${DIRECTION_SELECTOR}:checked`).getAttribute('value');
        this.datetime = node.querySelector(DATETIME_SELECTOR).value;
        this.baseEndpoint = node.querySelector('[data-endpoint]').getAttribute('data-endpoint');

        this.subscribe(`dom:${DATETIME_SELECTOR}:change`, e => this.handleDateChange(e));
        this.subscribe(`dom:${DIRECTION_SELECTOR}:change`, e => this.handleDirectionChange(e));
        this.subscribe(`dom:${FLIGHT_SEARCH_HERO_SUBMIT_BUTTON}:click`, e => this.handleSearchButtonClick(e));

        this.subscribe('shg:rw-autosuggest:choose', e => this.handleChoose(e));
        this.subscribe('shg:rw-autosuggest:enter-search', e => this.handleEnterSearch(e));

        // events are ignored during instantiation so delay this by 10ms
        window.setTimeout(() => {
            this.fireEndpointChangeEvent();
        }, 10);
    }

    handleChoose(customEvent) {
        const { searchQuery, query, isUrl } = customEvent.detail;
        const { direction } = this;

        trackEvent('flight-info', 'choose autosuggest', searchQuery, {
            dimension57: query,
            dimension64: direction
        });

        if (!isUrl) {
            this.submitForm();
        }
    }

    handleEnterSearch(customEvent) {
        const { query } = customEvent.detail;
        const { direction } = this;

        trackEvent('flight-info', 'search enter', query, {
            dimension64: direction
        });

        this.submitForm();
    }

    handleSearchButtonClick(mouseEvent) {
        const query = this.node.querySelector('input[type="search"]').value.trim();
        const { direction } = this;

        mouseEvent.preventDefault();

        trackEvent('flight-info', 'search button', query, {
            dimension64: direction
        });

        this.submitForm();
    }

    handleDateChange(event) {
        this.datetime = event.target.value;

        this.fireEndpointChangeEvent();
    }

    handleDirectionChange(event) {
        this.direction = event.target.value;

        this.fireEndpointChangeEvent();
    }

    submitForm() {
        const form = this.node.querySelector('form');

        if (form) {
            form.submit();
        }
    }

    fireEndpointChangeEvent() {
        const { baseEndpoint, datetime, direction } = this;
        const { encodeURIComponent } = window;

        const endpoint = `${baseEndpoint}&`
            + `datetime=${encodeURIComponent(datetime)}&direction=${encodeURIComponent(direction)}`;

        this.fireEvent({
            force: true,
            type: 'autosuggest-endpoint-change',
            endpoint
        });
    }
}
