import { trackEvent } from '@runway/util-analytics';
import {
    os, OperatingSystems, getiOSVersion 
} from '@runway/util-detect';

export const name = 'sms-link';

export default class SmsLink {
    get name() {
        return name;
    }

    constructor(node) {
        this.node = node;

        if (this.data.tracking) {
            // TODO check why this is done this way
            this.subscribe('dom:[data-component="sms-link"]:click', () => this.tracking());
        }

        if (os !== OperatingSystems.IOS) {
            return;
        }

        const replaceChar = getiOSVersion <= 8 ? ';' : '&';

        node.href = node.href.replace(/\?body=/, `${replaceChar}body=`);
    }

    /**
     * Adds analytics tracking to the sms-link. If the data attributes are set for
     * analytics it will use the data-attr otherwise, it will use some defaults.
     */
    tracking() {
        const { node, data } = this;
        const analyticsCategory = data.analyticsCategory || 'sms link';
        const analyticsAction = `${data.analyticsAction || 'clicked a sms link'}`;
        const analyticsLabel = data.analyticsLabel || node.textContent;

        trackEvent(analyticsCategory, analyticsAction, analyticsLabel);
    }
}
