import { iframeResize } from 'iframe-resizer'; // Used on https://www.schiphol.nl/en/work-at-schiphol/schipholpass/request-new/ for the new appointment tool

export const name = 'iframe-src';

export default class IframeSrc {
    get name() {
        return name;
    }

    constructor(node) {
        this.node = node;

        this.init();
    }

    init() {
        this.node.setAttribute('src', this.data.src);

        if (this.data.resize) {
            iframeResize({
                log: false,
                checkOrigin: false,
                enablePublicMethods: true
            }, '#bb-widget');

            // pass url params from parent window to the iframe
            const { searchParams: parentParams } = new URL(window.location);
            const { searchParams: iframeParams, origin, hash } = new URL(this.node.src);

            if (parentParams.toString()) {
                for (const [key, value] of parentParams.entries()) {
                    iframeParams.append(key, value);
                }

                // reload iframe with added params
                this.node.src = `${origin}?${iframeParams.toString()}${hash}`;
            }
        }
    }
}
