import * as Dom from '@runway/util-dom';

export const name = 'campaign-form';

const CLICK_DIMENSION_SCRIPT_URL = 'https://analytics-eu.clickdimensions.com/ts.js';

export default class CampaignForm {
    get name() {
        return name;
    }

    constructor() {
        window.cdAnalytics = {};

        Dom.loadScript(CLICK_DIMENSION_SCRIPT_URL, 5000)
            .then(() => {
                window.cdAnalytics = new window.clickdimensions.Analytics('analytics-eu.clickdimensions.com');

                window.cdAnalytics.setAccountKey('aql2Ku389QUmiYUfsLwDfv');
                window.cdAnalytics.setDomain(window.location.host);
                window.cdAnalytics.setScore(typeof window.cdScore === 'undefined' ? 0 : (window.cdScore === 0 ? null : window.cdScore));
                window.cdAnalytics.trackPage();
            });
    }
}
