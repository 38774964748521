import * as Dom from '@runway/util-dom';

const NAME_INPUT_SELECTOR = '#sre-employee-name-input';
const DEPARTMENT_INPUT_SELECTOR = '#sre-employee-department-input';
const EMPLOYEE_NODELIST_SELECTOR = '.rw-contact-list>.rw-card';

export const name = 'sre-employees-filter';

export default class SreEmployeeFilter {
    get name() {
        return name;
    }

    constructor(node) {
        this.node = node;

        this.nameInput = this.node.querySelector(NAME_INPUT_SELECTOR);

        if (this.nameInput) {
            this.nameInput.value = '';
        } // Firefox doesn't clear field when refreshing the page.

        const employeeNodeList = Array.from(this.node.querySelectorAll(EMPLOYEE_NODELIST_SELECTOR));

        this.employees = this.createEmployeeArrayFromNodelist(employeeNodeList);
        this.setupSubscriptions();
    }

    createEmployeeArrayFromNodelist(nodeList) {
        return nodeList.map(el => {
            const { departments, displayName } = Dom.getDataFromElement(el);

            return {
                departments: departments.split(';'),
                displayName: displayName.toUpperCase(),
                node: el
            };
        });
    }

    setupSubscriptions() {
        this.subscribe(`dom:${NAME_INPUT_SELECTOR}:keyup`, e => this.handleNameChanged(e));
        this.subscribe(`dom:${DEPARTMENT_INPUT_SELECTOR}:change`, e => this.handleDepartmentChanged(e));
    }

    filterEmployees() {
        this.employees.forEach(employee => {
            const { departments, displayName, node } = employee;
            let isVisible = true;

            if (this.departmentValue) {
                isVisible = departments.some(d => d === this.departmentValueLabel);
            }

            if (this.nameValue && this.nameValue.length && isVisible) {
                isVisible = displayName.indexOf(this.nameValue) !== -1;
            }

            if (isVisible) {
                node.classList.remove('visually-hidden');
            } else {
                node.classList.add('visually-hidden');
            }
        });
    }

    handleNameChanged(event) {
        this.nameValue = event.target.value.toUpperCase();
        this.filterEmployees();
    }

    handleDepartmentChanged(event) {
        this.departmentValue = parseInt(event.target.value, 10);
        this.departmentValueLabel = event.target.options[event.target.selectedIndex].text;
        this.filterEmployees();
    }
}
