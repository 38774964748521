/**
 * Add the node at the end of another node
 * @param {Object} node - the node to prepend
 * @param {Object} parentNode - the node which to prepend to
 */
export function prependNode(node, parentNode) {
    if ((node instanceof HTMLElement || node instanceof DocumentFragment)
        && (parentNode instanceof HTMLElement || parentNode instanceof DocumentFragment)) {
        parentNode.insertBefore(node, parentNode.firstChild);
    }
}
