/**
 * This class is responsible for connecting to the forecasted waiting times API.
 * This API describes how long the queues for security are expected to be.
 * At the moment (Q4 2022) both of these APIs are proxied by us so cloudflare is in front of them and the real APIs won't crash.
 *
 * The forecasted waiting times has 1 endpoint we use:
 * 1. Forecasted waiting times: latest/security_waiting_times_forecast.json
 */
export default class ForecastedWaitingTimesClient {
    constructor(forecastedWaitingTimesBaseUrl) {
        this.forecastedWaitingTimesBaseUrl = forecastedWaitingTimesBaseUrl;
    }

    /**
     * Fetch the forecasted waiting times for a security filter for the period of 3 hours before
     * boarding time to 1 hour after boarding time. (So 5 hours in total, if available)
     *
     * @param {string} securityFilter 'VF1', 'VF2' or 'VF3'
     * @param {Date} boardingTime
     */
    async fetchForecastedWaitingTimesAroundBoardingTime(
        securityFilter,
        boardingTime
    ) {
        return fetch(
            this.forecastedWaitingTimesBaseUrl +
                "latest/security_waiting_times_forecast.json"
        )
            .then(this.handleErrors)
            .then(response => response.json())
            .then(data => {
                return this.mapToWaitingTimes(
                    securityFilter,
                    boardingTime,
                    data
                );
            })
            .catch(error => {
                console.log(error);

                return [];
            });
    }

    /**
     * @returns {Array} waitingTimes [{hour, waitingTime (in minutes)}, ...]
     */
    mapToWaitingTimes(securityFilter, boardingTime, data) {
        if (
            !data ||
            !data.area ||
            !data.area[securityFilter] ||
            data.area[securityFilter].length === 0
        ) {
            return [];
        }

        const startTime = this.addHours(boardingTime, -3);
        const endTime = this.addHours(boardingTime, +1);

        return data.area[securityFilter]
            .filter(item => {
                const timeString = item.timestamp_local;
                const time = new Date(this.fixDateForSafari(timeString));

                return time >= startTime && time <= endTime;
            })
            .map(item => {
                const timeString = item.timestamp_local;
                const waitingTime = Math.max(
                    5,
                    item.waiting_time.bucket.upper_bound
                ); // always show bar in the graph even for value of 0 #noToothlessGraphs
                const hour = new Date(
                    this.fixDateForSafari(timeString)
                ).toLocaleString("nl", {
                    timeZone: "Europe/Amsterdam",
                    hour: "2-digit",
                    minute: "2-digit",
                });

                const handleMaxValue = value =>
                    value === Infinity || value === "Infinity" ? "120" : value;

                return {
                    hour,
                    waitingTime: handleMaxValue(waitingTime.toString()),
                };
            });
    }

    handleErrors(response) {
        if (!response.ok) {
            throw Error(response.statusText);
        }

        return response;
    }

    addHours(time, hours) {
        const t = new Date(time.getTime());

        t.setHours(t.getHours() + hours);
        t.setMinutes(0);

        return t;
    }

    /*
        When it comes to passing and calculating dates using the new Date(), the below code will not work on IE or Safari.
        So the solution is to pass a valid date format to Date() Object that supports across all browsers.
        Also, since Safari & IE browsers do not support the date format “yyyy-mm-dd”, here is a fast solution that would help in some cases.
        Read more about this here: https://www.linkedin.com/pulse/fix-invalid-date-safari-ie-hatem-ahmad/
    */
    fixDateForSafari(dateString) {
        return navigator.userAgent.match(/safari/i)
            ? dateString.replace(/-/g, "/")
            : dateString;
    }
}
