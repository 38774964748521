import * as Ajax from '@runway/util-ajax';
import * as Dom from '@runway/util-dom';
import { appendNode } from './helpers/append-node';
import { prependNode } from './helpers/prepend-node';

const BUTTON_SELECTOR = '[data-load-more-flights]';
const FLIGHTS_DAY_SELECTOR = '.flights__day';
const FLIGHT_CARDS_SELECTOR = '.flights__cards';
const LOAD_PREVIOUS_BUTTON_ID = 'flights-load-previous';

export const name = 'flight-list';

export class FlightList {
    get name() {
        return name;
    }

    constructor(node) {
        this.node = node;

        this.subscribe(`dom:${BUTTON_SELECTOR}:click`, e => this.handleClick(e));
    }

    handleClick(mouseEvent) {
        mouseEvent.preventDefault();

        const { target } = mouseEvent;
        const anchor = Dom.closest(target, '[href]');

        // prevent multiple clicks on the button
        if (anchor.hasAttribute('aria-disabled')
            && anchor.getAttribute('aria-disabled') === String(true)) {
            return;
        }

        anchor.setAttribute('aria-disabled', String(true));
        this.renderFlights(anchor);
    }

    async loadFlights(url) {
        let request;

        try {
            request = await Ajax.send(url);

            return Dom.html2node(request.response);
        } catch (error) {
            this.fireError({
                description: 'Error fetching flights.',
                error
            });

            return null;
        }
    }

    async renderFlights(anchor) {
        const url = anchor.getAttribute('href');
        const responseDOM = await this.loadFlights(url);
        const previousButtonClicked = anchor.id === LOAD_PREVIOUS_BUTTON_ID;
        // convenience function to use prepend or append where needed
        const insertNode = previousButtonClicked
            ? prependNode
            : appendNode;

        if (responseDOM) {
            const documentFragment = document.createDocumentFragment();
            const newAnchor = responseDOM.querySelector(`#${anchor.id}`);
            const newFlightDays = Array.from(responseDOM.querySelectorAll(FLIGHTS_DAY_SELECTOR));

            newFlightDays.forEach(newFlightsDay => {
                const flightsDay = this.node.querySelector(`#${newFlightsDay.id}`);

                if (flightsDay) {
                    const flightCards = flightsDay.querySelector(FLIGHT_CARDS_SELECTOR);
                    const newFlightCards = Array.from(newFlightsDay.querySelector(FLIGHT_CARDS_SELECTOR).children);

                    if (previousButtonClicked) {
                        newFlightCards.reverse();
                    }

                    newFlightCards.forEach(newFlightCard => {
                        const flightCard = flightsDay.querySelector(`#${newFlightCard.id}`);

                        if (!flightCard) {
                            insertNode(newFlightCard, flightCards);
                        }
                    });
                } else {
                    documentFragment.appendChild(newFlightsDay);
                }
            });

            if (newAnchor) {
                insertNode(newAnchor, documentFragment);
            }

            // replace the button that was just clicked with the new results
            // this could also be an empty document fragment
            anchor.parentNode.replaceChild(documentFragment, anchor);
        }
    }
}
