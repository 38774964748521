import Polly from '@runway/util-polly';
import * as Ajax from '@runway/util-ajax';
import { getEnvironment } from '@schiphol/env';
import fixOnScroll from './helpers/fixOnScroll';
import isNewHash from './helpers/isNewHash';

const name = 'live-blog';

const POLLY_INTERVAL = 30000; // 30 secs * 1000 ms
const AUTO_START = true;
const FIXED_UPDATES_BUTTON_CLASS = 'updates-available--fixed';
const UPDATE_BUTTON_THRESHOLD = 32; // pixels = 2rem
const VISUALLY_HIDDEN_CLASS = 'visually-hidden';
const ROTATING_CLASS = 'rotating';

const ENDPOINT = `${window.location.origin}/api/messages-for-travellers/messages`;
const HEADERS = [{
    name: 'Accept',
    value: 'application/vnd.schiphol-messages-for-travellers-api.v1+json'
}];

const previewSuffix = getEnvironment() !== 'prod' ? '?preview=' : '';

export default class LiveBlog {
    get name() {
        return name;
    }

    constructor(node) {
        this.node = node;
        this.updatesAvailable = false;

        this.cacheElements();
        this.setUpListeners();

        const interval =
            this.dynamicAreaNode.getAttribute('data-polly-interval') ||
            POLLY_INTERVAL;
        const cb = this.checkForUpdates.bind(this);

        this.polly = new Polly(cb, interval, AUTO_START);

        fixOnScroll(this.updatesAvailableNode, FIXED_UPDATES_BUTTON_CLASS, UPDATE_BUTTON_THRESHOLD);
    }

    cacheElements() {
        this.dynamicAreaNode = this.node.querySelector('[data-component="rw-dynamic-area"]');
        this.updatesAvailableNode = this.node.querySelector('.updates-available');
        this.updatesRefreshIconNode = this.node.querySelector('.updates-available__refresh > svg');
        this.numberOfMessages = this.node.querySelector('#number-of-updates');
        this.currentUpdatesToShow = 0;

        if (this.dynamicAreaNode) {
            this.messageId = this.dynamicAreaNode.getAttribute('data-message-id');
            this.endpoint = `${ENDPOINT}/${this.messageId}${previewSuffix}`;
            this.dynamicAreaInstance = window.SHG.retrieveInstanceFromNode(this.dynamicAreaNode);
        }
    }

    setUpListeners() {
        this.subscribe('dom:.updates-available > .rw-button:click', () => this.showMessages());
        this.subscribe('shg:rw-dynamic-area:start-load', () => this.animateUpdatesButton());
        this.subscribe('shg:rw-dynamic-area:end-load', () => this.hideUpdatesButton());
    }

    async checkForUpdates(times) {
        const message = await this.getMessage(times);

        if (!message) {
            return;
        }

        const isNewMessage = isNewHash(this.messageHash, message.hash);

        // Update current values
        this.messageHash = message.hash;
        const newMessagesToShow = message.updatesCount - (this.currentUpdatesCount || 0);

        this.currentUpdatesCount = message.updatesCount;

        if (!isNewMessage) {
            return;
        }

        if (newMessagesToShow > 0) {
            this.showUpdatesButton(newMessagesToShow);
        }
    }

    async getMessage() {
        let response;

        try {
            response = await Ajax.send(this.endpoint, {
                headers: HEADERS 
            });
        } catch (error) {
            this.fireError({
                description: 'Error fetching new live blog updates.',
                error
            });

            this.polly.stop();

            return null;
        }

        const { messages } = JSON.parse(response.responseText);
        const [message] = messages; // Take first message of the two (English and Dutch versions)

        const { live_blog_updates: liveBlogUpdates, live_blog_updates_hash: hash } = message;

        return {
            hash,
            updatesCount: liveBlogUpdates.length
        };
    }

    async showMessages() {
        try {
            await this.dynamicAreaInstance.load(`${window.location.pathname}${previewSuffix}`);
            this.node.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
        } catch (error) {
            this.fireError({
                description: 'Error loading new live blog updates on the page.',
                error
            });
        }
    }

    showUpdatesButton(newMessagesToShow) {
        this.currentUpdatesToShow = newMessagesToShow + this.currentUpdatesToShow;
        this.numberOfMessages.innerText = this.currentUpdatesToShow;
        this.updatesAvailableNode.classList.remove(VISUALLY_HIDDEN_CLASS);
    }

    hideUpdatesButton() {
        this.updatesRefreshIconNode.classList.remove(ROTATING_CLASS);
        this.updatesAvailableNode.classList.add(VISUALLY_HIDDEN_CLASS);
        this.currentUpdatesToShow = 0;
    }

    animateUpdatesButton() {
        this.updatesRefreshIconNode.classList.add(ROTATING_CLASS);
    }
}
