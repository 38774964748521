import * as Ajax from '@runway/util-ajax';

const LABEL_READONLY_CLASS = 'input__label--readonly';
const ZIP_PATTERN = /^\d{4}[a-zA-Z]{2}$/;

export const name = 'address-finder';

export default class AddressFinder {
    get name() {
        return name;
    }

    constructor(node) {
        this.node = node;

        this.http = Ajax;
        this.httpRequestInProgress = null;

        this.subscribe('dom:change', e => this.checkInputValues(e));
        this.subscribe('dom:select:change', () => this.checkFields());

        if (this.isValidCountry()) {
            this.disableFields();
        }
    }

    getZipcodeNode() {
        return this.node.querySelector('[data-name="zipcode"]');
    }

    getNumberNode() {
        return this.node.querySelector('[data-name="housenumber"]');
    }

    disableFields() {
        const optionalFields = Array.from(this.node.querySelectorAll('input[data-address-prefilled="true"]'));

        optionalFields.forEach(element => {
            if (element.value === '') {
                element.setAttribute('readonly', true);
                this.node.querySelector(`[for=${element.id}]`).classList.add(LABEL_READONLY_CLASS);
            }
        });
    }

    enableFields() {
        const optionalFields = Array.from(this.node.querySelectorAll('input[data-address-prefilled="true"]'));

        optionalFields.forEach(element => {
            element.removeAttribute('readonly');
            this.node.querySelector(`[for="${element.id}"]`).classList.remove(LABEL_READONLY_CLASS);
        });
    }

    checkFields() {
        if (this.isValidCountry()) {
            this.disableFields();
        } else {
            this.enableFields();
        }
    }

    /**
     * Function to check if a country list is included in the fieldset.
     * If yes, it checks that The Netherlands is selected
     */
    isValidCountry() {
        const countryNode = this.node.querySelector('[id^="country"]');

        if (countryNode) {
            return countryNode.value === 'NLD';
        }

        return true;
    }

    checkInputValues(event) {
        if (!this.isValidCountry()) {
            return;
        }

        const zipcodeNode = this.getZipcodeNode();
        const numberNode = this.getNumberNode();

        if (event.target !== zipcodeNode && event.target !== numberNode) {
            return;
        }

        const zipcode = zipcodeNode.value.toUpperCase().replace(/ /g, '');

        this.checkValidity(zipcode, numberNode.value);
    }

    /**
     * Checks if the zip and the street number are filled in
     * @param zipcode
     * @param number
     */
    checkValidity(zipcode, number) {
        if (zipcode === '' || number === '') {
            return;
        }

        this.findAddress(zipcode, number);
    }

    /**
     * Make the request to get the street and the city info
     * @param zipcode
     * @param number
     * @returns {*}
     */
    findAddress(zipcode, number) {
        const zipRegex = new RegExp(ZIP_PATTERN);

        if (!zipRegex.test(zipcode)) {
            return null;
        }

        const url = `/api/address/${zipcode}/${number}`;

        if (this.httpRequestInProgress && this.httpRequestInProgress.cancel) {
            this.httpRequestInProgress.cancel();
        }

        this.httpRequestInProgress = this.http.send(url, {});

        this.enableFields();

        this.httpRequestInProgress.then(
            result => {
                const { street, city } = JSON.parse(result.responseText);

                this.setStreet(street);
                this.setCity(city);
            },
            error => {
                this.fireError({
                    description: 'Error while fetching the address.',
                    error
                });
            }
        ).catch(error => {
            this.fireError({
                description: 'Error while fetching the address.',
                error
            });
        });

        return this.httpRequestInProgress;
    }

    /**
     * Sets the value of the street field
     * @param value
     */
    setStreet(value) {
        const input = this.node.querySelector('[data-name="street"]');

        input.value = value;
    }

    /**
     * Sets the value of the city field
     * @param value
     */
    setCity(value) {
        const input = this.node.querySelector('[data-name="city"]');

        input.value = value;
    }
}
